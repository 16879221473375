import FiltersModal from './FiltersModal';
import {ScrollView, StyleProp, View, ViewStyle} from 'react-native';
import {Category} from '../../features/category/categorySlice';
import CategoryButton from '../CategoryButton';
import {Button, makeStyles, useTheme} from '@rneui/themed';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import React, {useState} from 'react';
import {Coordinate, Nullable} from '../../index';
import {City} from '../../features/city/citySlice';
import {categoryHooks} from '../../hooks/categoryHooks';
import {geoLocationHooks} from '../../hooks/geoLocationHooks';

export type FiltersPanelStyles = { container: StyleProp<ViewStyle> }

const FiltersPanel = ({
  region,
  radius,
  city,
  category,
  updateFilters,
  setCategory,
  onMapViewClick,
  mapView,
  styles = {container: {}},
  buttonsOutline,
}: { region: Nullable<Coordinate>, buttonsOutline: boolean, styles: FiltersPanelStyles, mapView: boolean, onMapViewClick: Function, updateFilters: Function, setCategory: Function, radius: number, city: Nullable<City>, category: Nullable<Category> }) => {
  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  const [isAllowLocationModalVisible, setIsAllowLocationModalVisible] = useState(false);

  const {categories} = categoryHooks()
  const {theme} = useTheme()

  const onAllowLocationModalClose = () => {
    setIsAllowLocationModalVisible(false);
  };
  const onFiltersModalClose = () => {
    setIsFiltersModalVisible(false);
  };

  const {setFromBrowser} = geoLocationHooks()
  const style = buttonStyles({outline: buttonsOutline})

  return (
    <View style={[styles.container, {backgroundColor: 'none'}]}>
      {/*<AllowLocationModal isVisible={isAllowLocationModalVisible} onClose={onAllowLocationModalClose}/>*/}

      {categories.length ?
        <FiltersModal isVisible={isFiltersModalVisible} onClose={onFiltersModalClose} radius={radius}
          category={category} categories={categories} updateFilters={updateFilters}
          city={city}/>
        : <></>
      }

      {categories.length ?
        <ScrollView
          contentContainerStyle={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            overflow: 'visible',
            paddingTop: 5,
            gap: 2,
          }}
          pointerEvents="auto"
        >
          {
            categories.map((c: Category, i) => {
              const isActive = category?.id && category.id == c.id
              return <CategoryButton key={i}
                active={isActive}
                category={c.attributes.name}
                style={style.buttonStyle} onPress={() => {
                  setCategory(isActive ? null : c)
                }}
              />
            },
            )
          }
        </ScrollView>
        : <></>
      }

      <View style={{flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5, gap: 2}} pointerEvents="auto">
        {/*{!region ?*/}
        {/*  <Button*/}
        {/*    iconRight={true}*/}
        {/*    size="sm"*/}
        {/*    type="outline"*/}
        {/*    buttonStyle={[style.buttonStyle, {*/}
        {/*      borderColor: theme.colors.warning,*/}
        {/*      backgroundColor: theme.colors.warning,*/}
        {/*    }]}*/}
        {/*    titleStyle={{color: theme.colors.white}}*/}
        {/*    icon={<MaterialCommunityIcons name="map-marker" size={20} color={theme.colors.white}/>}*/}
        {/*    title="Allow Location"*/}
        {/*    onPress={() => {*/}
        {/*      setFromBrowser(navigator)*/}
        {/*      setIsAllowLocationModalVisible(true)*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  :*/}
        {/*  <></>*/}
        {/*}*/}

        <Button
          iconRight={true}
          size="sm"
          type="clear"
          buttonStyle={[style.buttonStyle]}
          titleStyle={{color: theme.colors.grey2}}
          icon={<MaterialCommunityIcons name="filter-menu-outline" size={20} color={theme.colors.grey2}/>}
          title="Filters"
          onPress={() => {
            setIsFiltersModalVisible(true)
          }}
        />
        <Button
          iconRight={true}
          size="sm"
          type="clear"
          buttonStyle={[style.buttonStyle]}
          titleStyle={{color: theme.colors.grey2}}
          icon={<MaterialCommunityIcons name={mapView ? 'format-list-bulleted' : 'map-outline'} size={20}
            color={theme.colors.grey2}/>}
          title={mapView ? 'List' : 'Map'}
          onPress={onMapViewClick}
        />
      </View>
    </View>)
}

export default FiltersPanel;

const buttonStyles = makeStyles((theme, props: { outline: boolean }) => {
  const borderOpts = props.outline ? {} : {
    borderColor: theme.colors.grey2,
    borderWidth: 1,
    backgroundColor: 'white',
    margin: 1,
  }
  return {
    buttonStyle: Object.assign({paddingHorizontal: 5}, borderOpts),
  }
});