import {Coordinate} from '..';
import {round} from 'lodash';


export type GoogleCoordinate = { lat: number, lng: number }
const COORDINATE_PRECiSION = 5
const distance = (coord1: Coordinate, coord2: Coordinate) => {
  const R = 6371000; // meters
  const dLat = toRad(coord2.latitude - coord1.latitude);
  const dLon = toRad(coord2.longitude - coord1.longitude);
  const lat1 = toRad(coord1.latitude);
  const lat2 = toRad(coord2.latitude);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

// Converts numeric degrees to radians
const toRad = (value: number) => {
  return value * Math.PI / 180;
}

const randomCoordinateDelta = (radius: number): Coordinate => {
  const rd = radius / 111300; //about 111300 meters in one degree

  const u = Math.random();
  const v = Math.random();

  const w = rd * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  return {
    latitude: y,
    longitude: x,
  };
}

const googleBoundsCoordinateToGoogleCoordinate = (gc: object) => {
  return {
    lat: gc.lat(),
    lng: gc.lng(),
  }
}

const offsetGoogleMapWindow = ({
  northEast,
  southWest,
  percentage,
}: { northEast: GoogleCoordinate, southWest: GoogleCoordinate, percentage: number }): GoogleCoordinate => {
  //---------- northEast
  //-------------/--|
  //------------/---|
  //-----------/----|
  //----------/-----|
  //---------/------|
  //--------/-------|
  //-------/--------|
  //------/---------|
  //--southWest-----C
  return {
    lng: round((northEast.lng - southWest.lng) * percentage, COORDINATE_PRECiSION),
    lat: 0.000,
  }
}

export {distance, toRad, randomCoordinateDelta, offsetGoogleMapWindow, googleBoundsCoordinateToGoogleCoordinate}