import {Linking, TextStyle, TouchableHighlight, View, ViewStyle} from 'react-native';
import {FontAwesomeIcon, FontAwesomeIconStyle} from '@fortawesome/react-native-fontawesome';
import {faWheat} from '@fortawesome/pro-solid-svg-icons/faWheat';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram'
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons/faLinkedin'
import {faGithub} from '@fortawesome/free-brands-svg-icons/faGithub'

import {makeStyles, Text, useTheme} from '@rneui/themed';
import {
  companyName,
  githubUrl,
  googleReviewUrl,
  instagramUrl,
  linkedinUrl,
  mailToUrl,
  pitchDeck,
  publicApiUrl,
  twitterUrl,
} from '../constants/Domain';
import {windowDimensions} from '../hooks/windowHooks';
import {faStars} from '@fortawesome/pro-solid-svg-icons';

const Footer = ({navigation}) => {
  const {theme} = useTheme()
  const {isPhoneOrSmallerScreen} = windowDimensions()
  const styles = useStyles()
  const iconSize = 20
  return (
    <View style={styles.view}>
      <View style={[styles.column, {flexDirection: 'column'}]}>
        <View style={{flexDirection: 'row'}}>
          <FontAwesomeIcon icon={faWheat} color={theme.colors.grey2} size={iconSize} style={styles.icon}/>
          <Text style={[styles.text, {fontWeight: '700'}]}>{companyName}</Text>
        </View>
        <Text style={styles.text}>Copyright @ 2023</Text>
      </View>

      <View style={[styles.column, {flexDirection: 'column'}]}>
        <TouchableHighlight onPress={() => navigation.push('Landing')}>
          <Text style={[styles.text]}>
            Home
          </Text>
        </TouchableHighlight>
        <TouchableHighlight onPress={() => navigation.push('About')}>
          <Text style={[styles.text]}>
            About
          </Text>
        </TouchableHighlight>
        <TouchableHighlight onPress={() => Linking.openURL(mailToUrl)}>
          <Text style={[styles.text]}>Support</Text>
        </TouchableHighlight>
      </View>
      <View style={[styles.column, {flexDirection: 'column'}]}>
        <TouchableHighlight onPress={() => Linking.openURL(googleReviewUrl)}>
          <View style={{flexDirection: 'row'}}>
            <FontAwesomeIcon icon={faStars} color={theme.colors.warning} size={iconSize}
              style={[styles.icon, {marginTop: -5}]}/>
            <Text style={[styles.text]}>Reviews</Text>
          </View>
        </TouchableHighlight>
        <TouchableHighlight onPress={() => Linking.openURL(publicApiUrl)}>
          <Text style={[styles.text]}>
            Developers
          </Text>
        </TouchableHighlight>
        <TouchableHighlight onPress={() => Linking.openURL(pitchDeck)}>
          <Text style={[styles.text]}>
            Pitch Deck
          </Text>
        </TouchableHighlight>
      </View>

      <View style={[styles.column, {flexDirection: isPhoneOrSmallerScreen ? 'column' : 'row'}]}>
        <TouchableHighlight onPress={() => Linking.openURL(twitterUrl)}>
          <FontAwesomeIcon icon={faTwitter} color={theme.colors.grey2} size={iconSize} style={styles.icon}/>
        </TouchableHighlight>
        <TouchableHighlight onPress={() => Linking.openURL(instagramUrl)}>
          <FontAwesomeIcon icon={faInstagram} color={theme.colors.grey2} size={iconSize} style={styles.icon}/>
        </TouchableHighlight>
        <TouchableHighlight onPress={() => Linking.openURL(linkedinUrl)}>
          <FontAwesomeIcon icon={faLinkedin} color={theme.colors.grey2} size={iconSize} style={styles.icon}/>
        </TouchableHighlight>
        <TouchableHighlight onPress={() => Linking.openURL(githubUrl)}>
          <FontAwesomeIcon icon={faGithub} color={theme.colors.grey2} size={iconSize} style={styles.icon}/>
        </TouchableHighlight>
      </View>
    </View>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    view: {flexDirection: 'row', justifyContent: 'center'} as ViewStyle,
    column: {paddingHorizontal: 10, rowGap: 5} as ViewStyle,
    text: {color: theme.colors.grey2} as TextStyle,
    icon: {marginRight: 10} as FontAwesomeIconStyle,
  }
});

export default Footer