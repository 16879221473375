import React from 'react';
import {Linking, ScrollView, TouchableOpacity} from 'react-native';
import HomeStackWrapper from '../../components/homeStack/HomeStackWrapper';
import {RootTabScreenProps} from '../../types';
import {Card, makeStyles, Text, useTheme} from '@rneui/themed';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faStars} from '@fortawesome/pro-solid-svg-icons';
import {faCupTogo} from '@fortawesome/pro-light-svg-icons';
import {buyMeACoffeeUrl, googleReviewUrl} from '../../constants/Domain';


export default function SettingsScreen({navigation}: RootTabScreenProps<'Settings'>) {
  const {theme} = useTheme()
  const iconSize = 20;

  const styles = settingsStyles()

  return (
    <HomeStackWrapper navigation={navigation}>
      <ScrollView horizontal={false} style={styles.container}>
        <TouchableOpacity onPress={() => Linking.openURL(googleReviewUrl)}>
          <Card containerStyle={styles.cardContainer}>
            <Card.Title style={styles.cardTitle}>
              <FontAwesomeIcon icon={faStars} size={iconSize} color={theme.colors.warning}
                style={styles.cardTitleIcon}/>
              Reviews
            </Card.Title>
            <Text style={styles.cardText}>
              We value your feedback!
            </Text>
            <Text style={styles.cardText}>
              We invite you to review our application and share your thoughts on your experience.
              Your input is invaluable to us as we strive to improve our app and provide you with the best user
              experience
              possible.
            </Text>
            <Text style={styles.cardLink}>Review us</Text>
          </Card>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => Linking.openURL(buyMeACoffeeUrl)}>
          <Card containerStyle={{maxWidth: 500, justifyContent: 'flex-start'}}>
            <Card.Title style={{justifyContent: 'flex-start', alignSelf: 'flex-start'}}>
              <FontAwesomeIcon icon={faCupTogo} size={iconSize} color={theme.colors.grey2} style={{paddingRight: 20}}/>
              Buy Us A Coffee
            </Card.Title>
            <Text style={{paddingBottom: 10, color: theme.colors.grey2}}>
              Support our endeavor with a simple act of kindness: buy us a coffee!
            </Text>
            <Text style={{color: theme.colors.grey2}}>
              As a dedicated team striving to create exceptional products and services, we rely on the generosity of our
              community to fuel our passion and drive us forward
            </Text>

            <Text style={{
              textDecorationLine: 'underline',
              color: theme.colors.grey2,
              marginTop: 20,
              alignSelf: 'flex-end',
            }}>Buy a
              coffee</Text>
          </Card>
        </TouchableOpacity>
      </ScrollView>
    </HomeStackWrapper>
  );
}

export const settingsStyles = makeStyles((theme) => {
  return {
    container: {
      padding: 10,
    },
    cardContainer: {
      maxWidth: 500, justifyContent: 'flex-start',
    },
    cardTitle: {
      justifyContent: 'flex-start', alignSelf: 'flex-start',
    },
    cardTitleIcon: {
      paddingRight: 10,
    },
    cardText: {
      paddingBottom: 10, color: theme.colors.grey2,
    },
    cardLink: {
      textDecorationLine: 'underline',
      color: theme.colors.grey2,
      marginTop: 20,
      alignSelf: 'flex-end',
    },
  }
})
