import {createNativeStackNavigator} from '@react-navigation/native-stack';
import ConfirmationScreen from '../screens/authScreens/ConfirmationScreen';
import ForgotPasswordScreen from '../screens/authScreens/ForgotPasswordScreen';
import LoginScreen from '../screens/authScreens/LoginScreen';
import RegisterScreen from '../screens/authScreens/RegisterScreen';
import ResetPasswordScreen from '../screens/authScreens/ResetPasswordScreen';
import SendConfirmationScreen from '../screens/authScreens/SendConfirmationScreen'
import LandingScreen from '../screens/authScreens/LandingScreen';
import ShowScreen from '../screens/authScreens/servicesScreens/ShowScreen';
import React from 'react';
import {ButtonsHeader, HeaderTitle} from '../components/authStack/AuthHeaderWrapper';
import SearchScreen from '../screens/authScreens/searchScreens/SearchScreen';
import AboutScreen from '../screens/authScreens/AboutScreen';


const AuthStack = createNativeStackNavigator();

export default function AuthStackNavigator() {
  return (
    <AuthStack.Navigator initialRouteName="Landing" screenOptions={{
      headerTitle: () => HeaderTitle(),
      headerRight: () => ButtonsHeader(),
    }}
    >
      <AuthStack.Screen name="Landing" component={LandingScreen} initialParams={{}} options={{title: 'Landing'}}/>
      <AuthStack.Screen name="About" component={AboutScreen} initialParams={{}} options={{title: 'About'}}/>
      <AuthStack.Screen name="Login" component={LoginScreen} initialParams={{}} options={{title: 'Login'}}/>
      <AuthStack.Screen name="Register" component={RegisterScreen} options={{title: 'Register'}}/>
      <AuthStack.Screen name="SendConfirmation" component={SendConfirmationScreen}
        options={{title: 'Send Confirmation'}}/>
      <AuthStack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{title: 'Forgot Password'}}/>
      <AuthStack.Screen name="ResetPassword" component={ResetPasswordScreen} options={{title: 'Reset Password'}}/>
      <AuthStack.Screen name="Confirmation" component={ConfirmationScreen} options={{title: 'Confirm Account'}}/>
      <AuthStack.Screen name="Search" component={SearchScreen} initialParams={{}} options={{title: 'Search'}}/>
      <AuthStack.Screen name="ServicesShow" component={ShowScreen} options={{title: 'Service'}}/>
    </AuthStack.Navigator>
  );
}