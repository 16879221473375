import {Nullable, Privacy} from '../..';
import {User} from '../user/userSlice';
import * as Yup from 'yup';

export const DEFAULT_RADIUS_KM_INITIAL = 50000
export type Service = {
  type: string;
  id: Nullable<string>;
  attributes: {
    currency: Nullable<string>;
    radius: Nullable<number>;
    name: Nullable<string>;
    description: Nullable<string>;
    cost: Nullable<string>;
    tags: Array<string>;
    imageUrl: Nullable<string>;
    slug: Nullable<string>;
    privacy: Privacy;
    createdAt: Nullable<Date>;
    updatedAt: Nullable<Date>;
  };
  relationships: {
    city: { data: any; };
    location: { data: any; };
    owner: { data: any };
    category: { data: any };
  };
}

export const blankService = (user: User): Service => {
  const slug = 'service'

  return {
    id: null,
    type: 'service',
    attributes: {
      currency: 'AUD',
      name: '',
      description: '',
      cost: '',
      tags: [],
      imageUrl: null,
      radius: null,
      slug: slug,
      privacy: 'is_public',
      createdAt: null,
      updatedAt: null,
    },
    relationships: {
      city: Object.assign({}, user.relationships.city),
      location: {data: null},
      owner: {data: {id: user.id, type: 'user'}},
      category: {data: null},
    },
  }
}

export const serviceSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
});