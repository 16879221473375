import React, {useContext, useEffect, useRef} from 'react';
import { StyleSheet, View } from 'react-native';
import { RootTabScreenProps } from '../../types';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import { confirm } from '../../services/CurrentUserService';
import { Button  } from '@rneui/themed';
import { sanitizedErrorsToFormik, sanitizeErrors as sanitize } from '../../utils/ErrorUtil';
import { AlertContext } from '../../contexts/AlertProvider';
import { compact } from 'lodash';
import { AUTHENTICATION_CATEGORY, CONFIRMATION_ACTION } from '../../utils/analyticsUtil';
import ReactGA from 'react-ga4';

type SanitizedErrors = import('../..').SanitizedErrors;
type ApiErrorResponse = import('../..').ApiErrorResponse;
  
const ConfirmationScreen = ({ navigation, route }: RootTabScreenProps<'Confirmation'>) => {
  const { toast } = useContext(AlertContext);
  const confirmAttempts = useRef(0)

  useEffect(()=> {
    const token = route.params['confirmation-token'];
    
    if (token) {
      confirmAttempts.current += 1

      ReactGA.event({
        category: AUTHENTICATION_CATEGORY,
        action: CONFIRMATION_ACTION,
        value:  confirmAttempts.current,
      });
      
      confirm(token).then(()=>{
        toast({message: 'Success'})
        navigation.push('Login')
      }).catch((err)=>{
        const sanitizedError = sanitize(err as Error | ApiErrorResponse) as SanitizedErrors
        const formikErrors = sanitizedErrorsToFormik(sanitizedError)
  
        if (formikErrors.base || sanitizedError.email) {
          toast({message: compact([formikErrors.base, sanitizedError.email]).join(','), timeout: 6000})
        }
      })
    }
  }, [route.params['confirmation-token']])

  return (
    <AuthStackWrapper onPress={()=>{navigation.push('Landing')}}> 
      <View>
        
        <Button
          title="Resend Confirmation?"
          buttonStyle={styles.clearButton}
        
          onPress={()=>{navigation.push('SendConfirmation')}}
        />
      </View>
      <View style={{
        alignSelf: 'flex-center',
        alignItems: 'center',
      }}>
        <Button
          title="Back to login"
          buttonStyle={styles.clearButton}
          type="clear"
          onPress={()=>{navigation.push('Login')}}
        />
 
      </View>
    </AuthStackWrapper>
  );
}

export default ConfirmationScreen

const styles = StyleSheet.create({
  containerStyle: {
    borderWidth: 1,
    borderRadius: 5,
  },
  actionButton: {
    marginTop:10,
    minWidth: 200,
  },
  clearButton: {
    justifyContent: 'flex-start',
  },
  errors: {
    color: 'red',
  },
});