import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {makeStyles, useTheme} from '@rneui/themed';
import {RootTabScreenProps} from '../../../types';
import {useGetServiceByIdQuery} from '../../../features/service/serviceApi';
import {EntitiesAdapterResult, Nullable} from '../../..';
import {Service} from '../../../features/service/serviceSlice';
import Footer from '../../../components/Footer';
import {AlertContext} from '../../../contexts/AlertProvider';
import ShowServiceCard, {ShowServiceCardStyles} from '../../../components/services/ShowServiceCard';
import {windowDimensions} from '../../../hooks/windowHooks';

export default function ShowScreen({route, navigation}: RootTabScreenProps<'Services'>) {
  const {toast} = useContext(AlertContext);
  const {theme} = useTheme()
  const [service, setService] = useState<Nullable<Service>>(null)
  const {isPhoneOrSmallerScreen, phoneWidth, width, height} = windowDimensions();
  const serviceCardStyles = showServiceCardStyles({isPhoneOrSmallerScreen, width, phoneWidth})

  if (!route.params.id) {
    toast({message: 'Invalid Service', timeout: 6000})
    navigation.push('Landing')
  }

  const {
    data: serviceEntities,
    error,
    isLoading,
    isSuccess,
  }: { data: EntitiesAdapterResult<Service>, error: any, isLoading: boolean, isSuccess: boolean } = useGetServiceByIdQuery(route.params.id);
  const styles = useStyles({isPhoneOrSmallerScreen})

  useEffect(() => {
    if (error) {
      toast({message: 'Invalid Service', timeout: 6000})
      navigation.push('Landing')
    } else if (serviceEntities) {
      setService(serviceEntities.entities[serviceEntities.ids[0]])
    }
  }, [isSuccess, error])

  return (
    <View style={{flexDirection: 'column', backgroundColor: 'white', flex: 1}}>
      {service &&
        <View style={styles.marketingContainer}>
          <View pointerEvents="auto" style={styles.servicesScrollView}>
            <ShowServiceCard service={service} imageSize={400} styles={serviceCardStyles} navigation={navigation}/>
          </View>
        </View>
      }
      <View style={{paddingVertical: 10}}>
        <Footer navigation={navigation}/>
      </View>

    </View>
  );
}


const showServiceCardStyles = makeStyles((theme, props: { isPhoneOrSmallerScreen: boolean, width: number, phoneWidth: number }) => {
  return {
    container: {
      paddingHorizontal: 20,
      width: props.isPhoneOrSmallerScreen ? props.width : props.phoneWidth,
    },
  } as ShowServiceCardStyles
});

const useStyles = makeStyles((theme, props: { isPhoneOrSmallerScreen: boolean }) => {
  const servicesScrollView = props.isPhoneOrSmallerScreen ? {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
  } : {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  }

  return {
    registerButton: {
      margin: 10,
      minWidth: 200,
    },
    servicesScrollView,
    marketingContainer: {flexDirection: 'column', flexGrow: 1, paddingBottom: 50},
  }
});