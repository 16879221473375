
import { Marker as GoogleMarker } from '@react-google-maps/api';
import { store } from '../../../store/store';
import { useGetCityByIdQuery } from '../../../features/city/cityApi';
import { extendedApiSlice } from '../../../features/location/locationApi';
import { Service } from '../../../features/service/serviceSlice';
import { Coordinate, EntitiesAdapterResult } from '../../..';
import { City } from '../../../features/city/citySlice';
import { useEffect, useState } from 'react';
import { Location } from '../../../features/location/locationSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { randomCoordinateDelta } from '../../../utils/coordinateUtil';
import { useTheme } from '@rneui/themed';

const OFFSET_LOCATION_KEY = 'googleMarkerCoordinateOffset'
const OFFSET_RADIUS = 5000; // 5 Km

const fetchCoordinateOffset = async (id: string): Promise<Coordinate> => {
  const key = [OFFSET_LOCATION_KEY,id].join('-')
  const offsetsRaw = await AsyncStorage.getItem(key)

  if (offsetsRaw) {
    return JSON.parse(offsetsRaw)
  } else {
    const offsets = randomCoordinateDelta(OFFSET_RADIUS)
    await AsyncStorage.setItem(key, JSON.stringify(offsets))
    return offsets
  }
}

const Marker = ({service, defaultPosition, onClick, onMouseOver}: {service: Service, defaultPosition: Coordinate, onClick: Function, onMouseOver: Function}) => {
  
  const [position, setPosition] = useState({lat: defaultPosition.latitude, lng: defaultPosition.longitude})
  const { data, isSuccess }: {data: EntitiesAdapterResult<City>} = useGetCityByIdQuery(service.relationships.city.data?.id)
  const { theme } = useTheme()
  
  const fetchAndSetLocationPosition = async (city: City) => {
    const {data: lData} = await store.dispatch(extendedApiSlice.endpoints.getLocationById.initiate(city.relationships.location.data.id))
    if (lData) {
      const { ids: lIds, entities: lEntities } = lData as EntitiesAdapterResult<Location>
      const location = lEntities[lIds[0]]
      const offsets = await fetchCoordinateOffset(service.id)

      setPosition({lat: location.attributes.lat + offsets.latitude, lng: location.attributes.lon + offsets.longitude})
    }
  }

  const onLoad = (marker: any) => {
    const customIcon = (opts) => Object.assign({
      path: 'M384 192c0 112-192 320-192 320S0 304 0 192C0 86 86 0 192 0S384 86 384 192z',
      fillColor: '#dea6f2',
      fillOpacity: 1,
      strokeColor: '#CA71EB',
      strokeWeight: 2,
      scale: 0.06,
    }, opts);

    
    marker.setIcon(customIcon({
      fillColor: '#dea6f2',
      strokeColor: '#CA71EB',
    }));
  }

  useEffect(()=>{
    if (isSuccess && data) {
      const { ids, entities } = data as EntitiesAdapterResult<City>
      const city = entities[ids[0]] as City
      void fetchAndSetLocationPosition(city)  
    }
  },[isSuccess])


  return (
    <GoogleMarker onLoad={onLoad} position={position} onClick={()=>{ onClick(service) }} onMouseOver={()=> {onMouseOver(service)}} onMouseOut={()=> {onMouseOver(null)}} /> 
  )
}

export default Marker