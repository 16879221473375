import React, {createContext, useEffect, useMemo} from 'react';
import {signIn as signInService, signOut as signOutService} from '../services/CurrentUserService';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {store} from '../store/store';
import {validateAuthToken} from '../services/apiSlice';
import {extendedApiSlice} from '../features/user/userApi';
import {User} from '../features/user/userSlice';
import {EntitiesAdapterResult} from '..';
import * as Sentry from 'sentry-expo';
import ReactGA from 'react-ga4';

export const AUTH_TOKEN_KEY = 'auth-context-token';

export type AuthData = {
  currentUser: null | User;
  token: null | string;
}

export type AuthContextData = {
  state: AuthData;
  signIn(): Promise<void>;
  signOut(): Promise<void>;
};

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({children}) => {
  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case 'SIGN_IN':
          return {
            ...prevState,
            currentUser: action.currentUser,
            token: action.token,
          }
        case 'SIGN_OUT':
          return {
            ...prevState,
            currentUser: null,
            token: null,
          }
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            currentUser: action.currentUser,
            token: action.token,
          }
      }
    },
    {
      currentUser: null,
      token: null,
    },
  );

  const authContext = useMemo(
    () => ({
      state,
      signIn: async (email: string, password: string) => {
        const token = await signInService(email, password)
        await AsyncStorage.setItem(AUTH_TOKEN_KEY, token);
        const {data} = await store.dispatch(extendedApiSlice.endpoints.getCurrentUser.initiate())

        const {ids, entities} = data as EntitiesAdapterResult<User>
        const user = entities[ids[0]] as User

        ReactGA.gtag('set', 'user_properties', {
          id: user.id,
          email: user.attributes.email,
        });
        Sentry.Browser.setUser({id: user.id, email: user.attributes.email})

        return dispatch({type: 'SIGN_IN', currentUser: user, token})
      },
      signOut: async () => {
        await signOutService(state.token)

        Sentry.Browser.setUser(null)

        await AsyncStorage.removeItem(AUTH_TOKEN_KEY)

        return dispatch({type: 'SIGN_OUT'})
      },
    }),
    [state.token],
  );

  useEffect(() => {
    const bootstrapAsync = async () => {
      let token;

      try {
        token = await AsyncStorage.getItem(AUTH_TOKEN_KEY) as string | undefined | null
        if (!token) {
          return
        } else if (!await validateAuthToken(token)) {
          token = null
          await AsyncStorage.removeItem(AUTH_TOKEN_KEY)
        } else {

          const {data} = await store.dispatch(extendedApiSlice.endpoints.getCurrentUser.initiate())
          const {ids, entities} = data as EntitiesAdapterResult<User>
          const user = entities[ids[0]] as User

          ReactGA.gtag('set', 'user_properties', {
            id: user.id,
            email: user.attributes.email,
          });
          Sentry.Browser.setUser({id: user.id, email: user.attributes.email})

          dispatch({type: 'RESTORE_TOKEN', token, currentUser: user});
        }
      } catch (e) {
        token = null
        await AsyncStorage.removeItem(AUTH_TOKEN_KEY)
      }
    };

    bootstrapAsync();
  }, []);

  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};

export default {
  AuthContext, AuthProvider,
}