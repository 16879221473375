import React from 'react';
import {StyleSheet, View} from 'react-native';
import {RootTabScreenProps} from '../../types';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import {Text, useTheme} from '@rneui/themed';
import Footer from '../../components/Footer';
import {windowDimensions} from '../../hooks/windowHooks';

const ConfirmationScreen = ({navigation, route}: RootTabScreenProps<'About'>) => {
  const {theme} = useTheme()
  const {isPhoneOrSmallerScreen} = windowDimensions();
  return (
    <AuthStackWrapper hideTitle={isPhoneOrSmallerScreen} onPress={() => {
      navigation.push('Landing')
    }}>
      <View style={{
        alignSelf: 'flex-center',
        alignItems: 'center',
        maxWidth: 600,
        padding: 20,
      }}>
        <Text h4 style={{color: theme.colors.grey2}}>
          At <Text style={{
            color: theme.colors.primary,
          }}>Purplegrain</Text>, our mission
          is to enable the
          agricultural
          industry by providing a comprehensive online
          marketplace where users can list, schedule, and book contract agricultural services. We are committed to
          empowering farmers, agricultural members, and service providers with a platform that furthers
          transparency, efficiency, and sustainability.
        </Text>
        <Text h4 style={{color: theme.colors.grey2, paddingTop: 20}}>
          Our vision is to create a community where farmers can
          easily connect with service providers
          and access a wide range of agricultural services.
        </Text>
      </View>
      <View style={{paddingBottom: 10, paddingTop: 30}}>
        <Footer navigation={navigation}/>
      </View>
    </AuthStackWrapper>
  );
}

export default ConfirmationScreen

const styles = StyleSheet.create({
  containerStyle: {
    borderWidth: 1,
    borderRadius: 5,
  },
  actionButton: {
    marginTop: 10,
    minWidth: 200,
  },
  clearButton: {
    justifyContent: 'flex-start',
  },
  errors: {
    color: 'red',
  },
});