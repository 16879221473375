import {apiSlice} from '../../services/apiSlice'
import normalize from 'json-api-normalizer';
import {User} from './userSlice';
import {createEntityAdapter} from '@reduxjs/toolkit';
import {EntitiesAdapterResult} from '../..';
import {mapKeysDeep} from 'deepdash/es/standalone';
import {paramCase} from 'change-case';

const normalizeApiResponse = (response: any) => {
  const data = Object.assign({}, normalize(response, {})).user;
  return [undefined, null].includes(data) ? [] : Object.keys(data).map((key) => {
    return data[key]
  }) as Array<User>;
}

const usersAdapter = createEntityAdapter<User>({})
const initialState = usersAdapter.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllUsers: builder.query<EntitiesAdapterResult<User>, void>({
      query: () => ({url: 'users'}),
      transformResponse: (response, meta, arg) => {
        const normalized = normalizeApiResponse(response) as Array<User>
        return usersAdapter.setAll(initialState, normalized);
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({type: 'Users', id} as const)),
      ],
    }),
    getUserById: builder.query<EntitiesAdapterResult<User>, string>({
      query: (id: string) => ({url: `users/${id}`}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as User
        return usersAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({type: 'User', id} as const)),
      ],
    }),
    getCurrentUser: builder.query<EntitiesAdapterResult<User>, void>({
      query: () => ({url: 'users/fetch-current-user'}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as User
        return usersAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({type: 'User', id} as const)),
      ],
    }),
    updateAvatar: builder.mutation({
      query: ({user, formData}: { user: User, formData: FormData }) => ({
        url: `users/${user.id}/update-avatar`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => error && [] || [
        {type: 'User', id: arg.user.id},
      ],
    }),
    updateUser: builder.mutation({
      query: ({id, ...rest}) => {

        return {
          url: `users/${id}`,
          method: 'PUT',
          body: {data: mapKeysDeep(rest, (v, k) => (typeof k === 'string' ? paramCase(k) : k))},
        }
      },
      invalidatesTags: (result, error, arg) => error && [] || [
        {type: 'User', id: arg.id},
      ],
    }),
  }),
})

export const {
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useUpdateUserMutation,
  useUpdateAvatarMutation,
} = extendedApiSlice
