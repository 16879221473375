import {ScrollView} from 'react-native-gesture-handler';
import {Service} from '../../features/service/serviceSlice';
import {makeStyles} from '@rneui/themed';
import {FlatList, ViewStyle} from 'react-native';
import {Nullable} from '../..';
import React, {useEffect, useRef} from 'react';
import ServiceListing, {ServiceListingStyles} from '../search/searchPanel/ServiceListing';

export type SearchPanelStyles = { container: ViewStyle }
const SearchPanel = ({
  width,
  services,
  activeService,
  serviceHover,
  onServiceClick,
  styles,
}: { activeService: Nullable<Service>, styles: SearchPanelStyles, serviceHover: Nullable<Service>, onServiceClick: Function, width: number, services: Service[] }) => {
  const avatarSize = width > 350 ? 120 : 100
  const infoBoxSize = width - avatarSize
  const activeWidth = width - 30
  const listingStyles = serviceListingStyles()
  const listingStylesHover = Object.assign({}, listingStyles, {
    view: {
      backgroundColor: 'rgba(202, 113, 235, 0.12)',
      flexDirection: 'row',
      padding: 15,
    },
  })
  const listingStylesActive = Object.assign({}, listingStyles, {
    view: {
      padding: 15,
      borderRadius: 5,
    },
  })

  const scrollViewRef = useRef<ScrollView>();

  useEffect(() => {
    if (activeService?.id) {
      scrollViewRef.current?.scrollTo(0, 0, true);
    }
  }, [activeService?.id]);

  return (
    <ScrollView ref={scrollViewRef} contentContainerStyle={[styles.container, {width: width}]} pointerEvents="auto">
      {
        activeService ?
          <ServiceListing service={activeService} avatarSize={200} infoBoxSize={activeWidth}
            styles={listingStylesActive}
            onPress={() => {
              onServiceClick(activeService)
            }}
            showTags={true}
          />
          : <></>
      }

      <FlatList
        keyExtractor={(item, index) => index.toString()}
        data={services}
        renderItem={({item}) => {
          const isHover = item.id == serviceHover?.id
          const isActive = activeService?.id === item.id
          return (
            isActive ?
              <></>
              :
              (<ServiceListing key={item.id} service={item} avatarSize={avatarSize} infoBoxSize={infoBoxSize}
                styles={isHover ? listingStylesHover : listingStyles}
                onPress={() => {
                  onServiceClick(item)
                }}/>)
          )
        }}
      />
    </ScrollView>
  )
}

export default SearchPanel;

const serviceListingStyles = makeStyles((theme) => {
  return {
    view: {
      flexDirection: 'row',
      padding: 15,
      borderRadius: 10,
      backgroundColor: theme.colors.white,
    },
    infoBox: {
      // backgroundColor: theme.colors.white,
      padding: 10,
    },
    avatar: {
      borderRadius: 10,
    },
    location: {
      flexWrap: 'wrap',
      textDecorationLine: 'underline',
    },
    title: {
      color: theme.colors.grey0,
      flexWrap: 'wrap',
    },
    cost: {
      paddingTop: 5,
    },
  } as ServiceListingStyles
});
