import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {Card, Text, useTheme} from '@rneui/themed';
import {RootTabScreenProps} from '../../types';
import {windowDimensions} from '../../hooks/windowHooks';
import Footer from '../../components/Footer';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faUsers} from '@fortawesome/pro-solid-svg-icons';
import {fetchApi} from '../../utils/fetchUtil';
import {Nullable} from '../../index';
import {companyDescription} from '../../constants/Domain';

export default function LandingScreen({route, navigation}: RootTabScreenProps<'Login'>) {

  // Styles
  const {theme} = useTheme()
  const {isTabletOrSmallerScreen, isPhoneOrSmallerScreen, height} = windowDimensions();
  const paddingTop = isTabletOrSmallerScreen ? 20 : Math.max(height - 740, 20)
  const [servicesTotal, setServicesTotal] = useState<Nullable<number>>(null);
  const [usersTotal, setSsersTotal] = useState<Nullable<number>>(null);

  useEffect(() => {
    fetchApi({path: '/users-and-services-statistics', method: 'GET'}).then((result) => {
      if (result) {
        setServicesTotal(result['services'])
        setSsersTotal(result['users'])
      }
    })
  }, [])

  return (
    <View style={{flexGrow: 1, backgroundColor: theme.colors.white}}>
      <AuthStackWrapper hideTitle={isPhoneOrSmallerScreen} onPress={() => {
      }}>
        <View style={{maxWidth: 450}}>
          <View style={{paddingBottom: (isTabletOrSmallerScreen ? 10 : 40)}}>
            <Text h3 style={{color: theme.colors.primary}}>{companyDescription}</Text>
          </View>
          <TouchableOpacity onPress={() => navigation.navigate('Register')}>
            <Card>
              <Card.Title
                style={{
                  color: theme.colors.grey2,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 0,
                  margin: 0,
                }}>
                List for free
                <MaterialCommunityIcons name="arrow-right" size={20} color={theme.colors.grey2}
                  style={{paddingLeft: 20}}/>
              </Card.Title>
            </Card>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('Search')}>
            <Card>
              <Card.Title style={{
                color: theme.colors.grey2, flexDirection: 'row', justifyContent: 'space-between', padding: 0,
                margin: 0,
              }}>
                Search
                <MaterialCommunityIcons name="arrow-right" size={20} color={theme.colors.grey2}
                  style={{paddingLeft: 60}}/>
              </Card.Title>
            </Card>
          </TouchableOpacity>
          <View style={{flexDirection: 'row', justifyContent: 'center', padding: 20, gap: 20, minHeight: 20}}>
            {usersTotal ?
              <View style={{flexDirection: 'row'}}>
                <FontAwesomeIcon icon={faUsers} color={theme.colors.grey2} size={20} style={{paddingRight: 10}}/>
                <Text>{usersTotal}+ users</Text></View>
              :
              <></>
            }
            {servicesTotal ?
              <View style={{flexDirection: 'row'}}>
                <Icon
                  name="tractor"
                  color={theme.colors.grey2}
                  size={20}
                  style={{paddingRight: 10}}/>
                <Text>{servicesTotal}+ services</Text></View>
              :
              <></>
            }
          </View>
        </View>
      </AuthStackWrapper>
      <View style={{paddingBottom: 10, paddingTop: paddingTop, backgroundColor: theme.colors.white}}>
        <Footer navigation={navigation}/>
      </View>
    </View>
  );
}

//
// const useStyles = makeStyles((theme, props: { isTabletOrSmallerScreen: boolean, height: number }) => {
//   const padding = props.isTabletOrSmallerScreen ? 10 : 30
//   const scrollViewStyles = props.isTabletOrSmallerScreen ? {
//     alignContent: 'center',
//     alignItems: 'center',
//     alignSelf: 'center',
//   } : {
//     flexDirection: 'row',
//     flexWrap: 'wrap',
//   }
//
//   return {
//     container: {
//       flexDirection: 'column',
//       minHeight: props.height - 64,
//     },
//     marketingContainer: {flexDirection: 'column', flexGrow: 1, padding: padding},
//     servicesScrollView: scrollViewStyles,
//     errors: {
//       color: 'red',
//     },
//   }
// });