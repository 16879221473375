
import { apiSlice } from '../../services/apiSlice'
import normalize from 'json-api-normalizer';
import { Location } from './locationSlice';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntitiesAdapterResult } from '../..';

const normalizeApiResponse = (response: any) => {
  const data = Object.assign({}, normalize(response, { })).location
  return [undefined, null].includes(data) ? [] : Object.keys(data).map((key) => { return data[key] }) as Array<Location>
}
  
const locationAdapter = createEntityAdapter<Location>({})
const initialState = locationAdapter.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getLocationById: builder.query<EntitiesAdapterResult<Location>, string>({
      query: (id: string) => ({url: `locations/${id}`}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as Location
        return locationAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({ type: 'Location', id } as const)),
      ],
    }),
  }),
})
  
export const { useGetLocationByIdQuery, useLazyGetLocationByIdQuery } = extendedApiSlice