import React, {useContext, useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import HomeStackWrapper from '../../components/homeStack/HomeStackWrapper';
import {RootTabScreenProps} from '../../types';
import {Avatar, Button, Card, Input, Text, useTheme} from '@rneui/themed';
import {AuthContext, AuthData} from '../../contexts/AuthProvider';
import UserAvatar from 'react-native-user-avatar';
import {extendedApiSlice as extendedUserApiSlice} from '../../features/user/userApi';
import {User} from '../../features/user/userSlice';
import * as ImagePicker from 'expo-image-picker';
import {ENV} from '../../environment';
import extName from 'ext-name';
import {Formik} from 'formik';
import {store} from '../../store/store';
import CityAutocomplete from '../../components/CityAutocomplete';
import {ApiErrorResponse, Nullable, SanitizedErrors} from '../..';
import {City} from '../../features/city/citySlice';
import {extendedApiSlice as extendedCityApiSlice} from '../../features/city/cityApi';
import {AlertContext} from '../../contexts/AlertProvider';
import {sanitizedErrorsToFormik, sanitizeErrors} from '../../utils/ErrorUtil';
import {windowDimensions} from '../../hooks/windowHooks';
import UpdateContactInfo from '../../components/profile/UpdateContactInfo';

const updateAvatar = async (user: User) => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: false,
    base64: false,
  });

  if (!result.cancelled) {

    const response = await fetch(result.uri)
    const blob = await response.blob()

    if (blob.size > ENV.image_upload_size) {
      return
    }
    const mime = extName.mime(blob.type)[0]

    const file = new File([blob], `avatar.${mime.ext}`, {type: blob.type});

    const formData = new FormData();
    formData.append('avatar', file)

    await store.dispatch(extendedUserApiSlice.endpoints.updateAvatar.initiate({user, formData}))
  }
}

const removeAvatar = async (user: User) => {
  const formData = new FormData();
  await store.dispatch(extendedUserApiSlice.endpoints.updateAvatar.initiate({user, formData}))
}

export default function ProfileScreen({navigation}: RootTabScreenProps<'Profile'>) {
  const [city, setCity] = useState<Nullable<City>>(null);
  const {theme} = useTheme()
  const {state} = useContext(AuthContext)
  const {currentUser} = state as AuthData
  const {toast} = React.useContext(AlertContext);
  const {isPhoneOrSmallerScreen, phoneWidth, width, height} = windowDimensions();
  const containerWidth = isPhoneOrSmallerScreen ? width : phoneWidth;

  useEffect(() => {
    const user = currentUser as User
    if (user.relationships.city.data?.id) {
      store.dispatch(extendedCityApiSlice.endpoints.getCityById.initiate(user.relationships.city.data.id)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          const city = entities[ids[0]] as City
          setCity(city)
        }
      })
    }
  }, [])

  const handleErrors = (errors, setErrors) => {
    const sanitizedError = sanitizeErrors(errors as Error | ApiErrorResponse) as SanitizedErrors

    if (sanitizedError) {
      const formikErrors = sanitizedErrorsToFormik(sanitizedError)
      setErrors(formikErrors)

      if (formikErrors.base) {
        toast({message: formikErrors.base, timeout: 10000})
      }
    } else {
      console.error(errors)
    }
  }

  return (
    <HomeStackWrapper navigation={navigation}>
      <ScrollView horizontal={false} style={{padding: 10}}>
        {currentUser &&
          <Formik
            enableReinitialize={true}
            initialValues={{...currentUser.attributes, cityId: currentUser.relationships.city.data?.id}}
            onSubmit={async (values, {setSubmitting, setErrors, setTouched}) => {

              const cityData = values.cityId ? {data: {type: 'city', id: values.cityId}} : null
              const updatedAttributes = Object.assign({}, currentUser.attributes, values)
              const updatedRelationships = Object.assign({}, currentUser.relationships, {city: cityData})
              const updated = Object.assign({}, currentUser, {
                attributes: updatedAttributes,
                relationships: updatedRelationships,
              })

              try {
                const {error} = await store.dispatch(extendedUserApiSlice.endpoints.updateUser.initiate(updated))

                if (error) {
                  handleErrors(error.data.errors, setErrors)
                } else {
                  setTouched({})
                  void toast({message: 'Updated User'})
                }
              } catch (error) {
                handleErrors(error, setErrors)
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              isSubmitting,
              touched,
              setFieldValue,
              setFieldTouched,
            }) => (
              <View style={{maxWidth: containerWidth}}>
                <Card>
                  <View
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    <View style={{
                      flexDirection: 'row',
                    }}>
                      {currentUser.attributes.avatarUrl ?
                        <Avatar
                          size={100}
                          avatarStyle={{borderRadius: 5}}
                          containerStyle={{marginRight: 20}}
                          source={{uri: currentUser.attributes.avatarUrl}}
                        />
                        :
                        <UserAvatar size={100} name={currentUser.attributes.name || currentUser.attributes.email}/>
                      }
                    </View>
                    <View>
                      <Text h4 style={{marginVertical: 10}}>
                        {currentUser.attributes.email}
                      </Text>
                      <View>
                        <Input
                          label="Name"
                          onChangeText={handleChange('name')}
                          onBlur={handleBlur('name')}
                          value={values.name || ''}
                        />
                        <CityAutocomplete
                          label="Default Location (used for services and search)"
                          id={values.cityId}
                          onUpdate={(city: Nullable<City>) => {
                            setCity(city)
                            setFieldValue('cityId', city?.id)
                            setFieldTouched('cityId', true)
                          }}
                        />

                        {values.contactInfo &&
                          <View>
                            <Text h4 style={{marginVertical: 10}}>
                              Service Contact Info
                            </Text>
                            <UpdateContactInfo contactInfo={values.contactInfo}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}/>
                          </View>
                        }
                      </View>
                    </View>
                  </View>
                </Card>
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', padding: 10}}>
                  <Button type="clear" onPress={() => {
                    handleSubmit()
                  }} title="Update" titleStyle={{color: theme.colors.primary}}
                  disabled={isSubmitting || Object.values(touched).every((v) => !v)}/>
                  <Button type="clear" onPress={() => {
                    updateAvatar(currentUser)
                  }} title={currentUser.attributes.avatarUrl ? 'Update Avatar' : 'Add Avatar'}
                  titleStyle={{color: theme.colors.grey0}}/>
                  {currentUser.attributes.avatarUrl &&
                    (<Button type="clear" onPress={() => {
                      removeAvatar(currentUser)
                    }} title="Remove Avatar" titleStyle={{color: theme.colors.grey0}}/>)
                  }
                </View>
              </View>
            )}
          </Formik>
        }
      </ScrollView>
    </HomeStackWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
  },
});
