import React, {useContext, useEffect, useState} from 'react';
import HomeStackWrapper from '../../components/homeStack/HomeStackWrapper';
import {RootTabScreenProps} from '../../types';
import Search from '../../components/Search';
import {Service} from '../../features/service/serviceSlice';
import {geoLocationHooks} from '../../hooks/geoLocationHooks';
import {Coordinate, EntitiesAdapterResult, Nullable} from '../../index';
import {City} from '../../features/city/citySlice';
import {AuthContext, AuthData} from '../../contexts/AuthProvider';
import {extendedApiSlice} from '../../features/city/cityApi';
import {extendedApiSlice as extendedLocationApiSlice} from '../../features/location/locationApi';
import {store} from '../../store/store';
import {Location} from '../../features/location/locationSlice';
import {horshamGeolocation} from '../../constants/Domain';

export default function SearchScreen({navigation}: RootTabScreenProps<'Search'>) {
  // GeoLocation
  const {coordinate, setCoordinate} = geoLocationHooks()
  // State
  const {state} = useContext(AuthContext)
  const {currentUser} = state as AuthData
  const [region, setRegion] = useState<Nullable<Coordinate>>(null);

  const setCityFromData = async (data?: EntitiesAdapterResult<City>) => {
    if (data) {
      const {ids, entities} = data;
      const city = entities[ids[0]] as City

      try {
        const {data: lData} = await store.dispatch(extendedLocationApiSlice.endpoints.getLocationById.initiate(city.relationships.location.data.id))

        if (lData) {
          const {ids: lIds, entities: lEntities} = lData as EntitiesAdapterResult<Location>
          const location = lEntities[lIds[0]]
          const coordinate = {latitude: location.attributes.lat, longitude: location.attributes.lon}
          void setCoordinate(coordinate)
        }
      } catch (e) {
        //passthrough
      } finally {
        if (!coordinate && !region) {
          setCoordinate(horshamGeolocation)
        }
      }
    }
  }

  useEffect(() => {
    if (region) {
      return
    }

    if (coordinate) {
      setRegion(coordinate)
    }

    const cityId = currentUser.relationships.city.data?.id

    if (cityId) {
      store.dispatch(extendedApiSlice.endpoints.getCityById.initiate(cityId)).then(({data, isSuccess}) => {
        if (isSuccess) {
          setCityFromData(data)
        }
      })
    } else {
      store.dispatch(extendedApiSlice.endpoints.getCityByIp.initiate()).then(({data, isSuccess}) => {
        if (isSuccess) {
          setCityFromData(data)
        }
      })
    }
  }, [currentUser.relationships.city.data?.id, coordinate])

  return (
    <HomeStackWrapper navigation={navigation} padding={0}>
      <Search defaultMapView={true} region={region} setRegion={setRegion} onServiceClick={(service: Service) => {
        navigation.navigate('ServicesShow', {id: service.id, slug: service.attributes.slug})
      }}/>
    </HomeStackWrapper>
  );
}