import React, {useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import {Button, Input, useTheme} from '@rneui/themed';
import {RootTabScreenProps} from '../../types';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import {register} from '../../services/CurrentUserService';
import {SanitizedErrors} from '../..';
import {sanitizedErrorsToFormik, sanitizeErrors} from '../../utils/ErrorUtil';
import {AlertContext} from '../../contexts/AlertProvider';
import * as Yup from 'yup';
import {ErrorMessage, Formik} from 'formik';
import ReactGA from 'react-ga4';
import {AUTHENTICATION_CATEGORY, REGISTER_ACTION} from '../../utils/analyticsUtil';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {useTogglePasswordVisibility} from '../../hooks/formfieldHooks';
import {Text} from '@rneui/base';
import {ENV} from '../../environment';
import ReCAPTCHA from 'react-google-recaptcha';

type ApiErrorResponse = import('../..').ApiErrorResponse

const siteKey = ENV.production.googleRecaptchaEnterpriseId;

export default function RegisterScreen({navigation, route}: RootTabScreenProps<'Register'>) {
  const {toast} = React.useContext(AlertContext);
  const defaultValues =
    {email: route.params?.email || '', password: '', captcha: '' || null}
  const registerSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    captcha: Yup.string().required('Required'),
  });
  const registerAttempts = useRef(0)
  const recaptchaRef = React.useRef<ReCAPTCHA>();

  const {theme} = useTheme()
  const {passwordVisibility, rightIcon, handlePasswordVisibility} = useTogglePasswordVisibility();

  return (
    <AuthStackWrapper onPress={() => {
      navigation.push('Landing')
    }}>
      <Formik
        enableReinitialize={true}
        validationSchema={registerSchema}
        initialValues={defaultValues}
        onSubmit={async (values, {setErrors}) => {
          registerAttempts.current += 1;
          ReactGA.event({
            category: AUTHENTICATION_CATEGORY,
            action: REGISTER_ACTION,
            value: registerAttempts.current,
          });

          try {
            const val = await recaptchaRef.current!.getValue();
            await register(values.email, values.password)
            toast({message: 'Success. Please confirm your account from the provided email', timeout: 6000})
            navigation.push('Login');
          } catch (err) {
            const sanitizedError = sanitizeErrors(err as Error | ApiErrorResponse) as SanitizedErrors
            if (sanitizedError) {
              const formikErrors = sanitizedErrorsToFormik(sanitizedError)
              setErrors(formikErrors)

              if (formikErrors.base) {
                toast({message: formikErrors.base, timeout: 6000})
              }
            } else {
              console.error(err)
            }
          }
        }
        }
      >
        {({errors, touched, handleChange, handleSubmit, values}) => (
          <View>
            <Input
              label="Email"
              onChangeText={handleChange('email')}
              value={values.email}
              errorStyle={styles.errors}
              errorMessage={touched.email && errors.email}
              inputContainerStyle={styles.containerStyle}
            />
            <Input
              label="Password"
              rightIcon={<MaterialCommunityIcons name={rightIcon} size={20} onPress={handlePasswordVisibility}
                color={theme.colors.grey2}/>}
              rightIconContainerStyle={{marginVertical: 0}}
              onChangeText={handleChange('password')}
              secureTextEntry={passwordVisibility}
              value={values.password}
              errorStyle={styles.errors}
              errorMessage={touched.password && errors.password}
              inputContainerStyle={styles.containerStyle}
              onSubmitEditing={() => {
                if (values.email && values.password) {
                  handleSubmit()
                }
              }}
            />

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={siteKey}
              onChange={(value) => {
                values.captcha = value
              }}
            />
            <ErrorMessage name="captcha">{(msg) => (<Text style={{color: 'red'}}>Required</Text>)}</ErrorMessage>
            <View style={{
              flex: 1,
              alignSelf: 'flex-center',
              alignItems: 'center',
            }}>
              <Button
                title="Back to login"
                buttonStyle={styles.clearButton}
                type="clear"
                onPress={() => {
                  navigation.push('Login', {email: values.email})
                }}
              />
            </View>
            <Button
              title="Register"
              loading={false}
              onPress={handleSubmit}
              style={styles.actionButton}
            />
          </View>
        )}
      </Formik>
    </AuthStackWrapper>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    borderWidth: 1,
    borderRadius: 5,
  },
  actionButton: {
    margin: 10,
    minWidth: 200,
  },
  clearButton: {
    justifyContent: 'flex-start',
  },
  errors: {
    color: 'red',
  },
});