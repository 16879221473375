import {assign} from 'lodash';
import getEnvVars from '../environment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {AUTH_TOKEN_KEY} from '../contexts/AuthProvider';
import * as Sentry from 'sentry-expo';

type ApiErrorResponse = import('..').ApiErrorResponse

const {apiBaseUrl, apiVersionPath, apiUsersPath} = getEnvVars();

const setQueryParams = (uri: URL, params: Record<string, any> | null): URL => {
  for (const key in params) {
    uri.searchParams.set(key, params[key])
  }
  return uri
}
const setOpts = (uri: URL, method: string, params: Record<string, any> | null, headers: Record<string, any>, body: null | any = null) => {
  const opts = {
    method,
    headers: assign({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': uri.host,
      'Access-Control-Allow-Headers': '*',
    }, headers),
    body: body,
  } as Record<string, any>

  if (params) {
    if ((method === 'POST' || method === 'PUT')) {
      opts.body = JSON.stringify(params)
    }
  }
  return opts
}

const handleRailsResponse = async (res: any, path: string) => {
  if (res.ok && path.match(/sign-in/)) {
    return res.headers.get('Authorization')?.split(' ')?.[1]
  } else if (res.status === 204) {
    return
  } else if (res.ok) {
    return await res.json()
  } else {
    throw (await res.json() as ApiErrorResponse).errors
  }
}

export const fetchApi = async ({
  path,
  params = {},
  method = 'POST',
  headers = {},
}: { path: string, params?: Record<string, any>, method?: string, headers?: Record<string, any> }): Promise<any> => {
  path = apiVersionPath + path.replace(/^(\/)/, '')

  let uri = new URL(apiBaseUrl)
  uri.pathname = path

  if (method === 'GET') {
    uri = setQueryParams(uri, params);
  }

  try {
    const res = await fetch(uri.toString(), setOpts(uri, method, params, headers))
    return await handleRailsResponse(res, path)
  } catch (err) {
    Sentry.Browser.captureException(err)
    throw err
  }
}

export const fetchUsers = async ({
  path,
  params = {},
  method = 'POST',
  headers = {},
}: { path: string, params?: Record<string, any>, method?: string, headers?: Record<string, any> }): Promise<any> => {
  path = apiUsersPath + path.replace(/^(\/)/, '')

  let uri = new URL(apiBaseUrl)
  uri.pathname = path

  if (method === 'GET') {
    uri = setQueryParams(uri, params);
  }

  try {
    const res = await fetch(uri.toString(), setOpts(uri, method, params, headers))
    return await handleRailsResponse(res, path)
  } catch (err) {
    Sentry.Browser.captureException(err)
    throw err
  }
}

export const fetchAvatarUpdate = async (id: string, file: File) => {
  const baseUri = new URL(apiBaseUrl)
  baseUri.pathname = `${apiVersionPath}/users/${id}/update-avatar`

  const formData = new FormData();
  formData.append('avatar', file)

  const token = await AsyncStorage.getItem(AUTH_TOKEN_KEY)

  const res = await fetch(baseUri.toString(), {
    method: 'POST',
    body: formData,
    headers: {
      'Access-Control-Allow-Origin': baseUri.host,
      'Access-Control-Allow-Headers': '*',
      'Authorization': `Bearer ${token}`,
    },
  });

  try {
    return await handleRailsResponse(res, baseUri.pathname)
  } catch (err) {
    Sentry.Browser.captureException(err)
    throw err
  }
}


export default {
  fetchApi, fetchUsers, fetchAvatarUpdate,
}