import React, {useEffect, useRef, useState} from 'react';
import {View} from 'react-native';
import {Coordinate, EntitiesAdapterResult, Nullable} from '..';
import {Service} from '../features/service/serviceSlice';
import {Category} from '../features/category/categorySlice';
import SearchList, {SearchListStyles} from './search/SearchList';
import {City} from '../features/city/citySlice';
import {Location} from '../features/location/locationSlice';
import SearchMap, {SearchMapStyles} from './search/SearchMap';
import {makeStyles, useTheme} from '@rneui/themed';
import {windowDimensions} from '../hooks/windowHooks';
import {extendedApiSlice as extendedServiceApiSlice} from '../features/service/serviceApi';
import {extendedApiSlice as extendedLocationApiSlice} from '../features/location/locationApi';
import {store} from '../store/store';
import {borderColor} from '../constants/Colors';
import FiltersPanel from './search/FiltersPanel';

const Search = ({
  navigation,
  defaultMapView,
  onServiceClick,
  setRegion,
  region,
}: { defaultMapView: boolean, navigation, onServiceClick: Function, region: Coordinate, setRegion: Function }) => {
  // Constants
  const [services, setServices] = useState<Array<Service>>([])
  const [category, setCategory] = useState<Nullable<Category>>(null);
  const [radius, setRadius] = useState<number>(500);
  const [city, setCity] = useState<Nullable<City>>(null);
  const [mapView, setMapView] = useState<boolean>(defaultMapView)

  const mapRef = useRef(null);
  const {theme} = useTheme()
  const {isTabletOrSmallerScreen} = windowDimensions();

  const onRegionChange = async ({
    coordinate,
    city,
    radius,
    category,
  }: { coordinate: Coordinate, category: Nullable<Category>, radius: number, city: Nullable<City> }, force = false) => {

    if (!coordinate) {
      return
    }

    if (coordinate != region && !force) {
      setRegion(coordinate)
    }

    const {data} = await store.dispatch(extendedServiceApiSlice.endpoints.getServicesByCoordinate.initiate({
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
      categoryId: category?.id,
      radius: radius * 1000,
    }))

    if (data) {
      const {ids, entities} = data;
      const newServices = ids.map((id) => entities[id]) as Array<Service>
      setServices(newServices)
    }
  }
  const handleUpdateFilters = async ({
    city,
    radius,
    category,
  }: { city: Nullable<City>, radius: number, category: Nullable<Category> }) => {

    setRadius(radius)
    setCity(city)
    setCategory(category)

    if (region && !city) {
      onRegionChange({coordinate: region, category, radius, city})
    } else {
      const {data: lData} = await store.dispatch(extendedLocationApiSlice.endpoints.getLocationById.initiate(city.relationships.location.data.id))

      if (lData) {
        const {ids: lIds, entities: lEntities} = lData as EntitiesAdapterResult<Location>
        const location = lEntities[lIds[0]]
        const coordinate = {latitude: location.attributes.lat, longitude: location.attributes.lon}
        void setRegion(coordinate)

        onRegionChange({coordinate, category, radius, city})
      }
    }
  }

  const handleCategoryUpdate = (category: Nullable<Category>) => {
    setCategory(category)
    if (region) {
      onRegionChange({coordinate: region, category, radius, city}, true)
    }
  }

  const toggleMapView = () => {
    setTimeout(() => {
      setMapView(!mapView)
    }, mapView ? 500 : 1000)
  }

  useEffect(() => {
    if (region) {
      onRegionChange({coordinate: region, category, radius, city})
    }
  }, [region])

  const styles = searchStyles({isTabletOrSmallerScreen, hideSearchPanelContainer: services.length == 0})

  return (
    <View style={{flex: 1}}>
      {
        region ?
          (mapView ?
            <SearchMap radius={radius} region={region} onRegionChange={onRegionChange} services={services}
              onServiceClick={onServiceClick}
              category={category}
              onMapViewClick={toggleMapView}
              mapRef={mapRef}
              setCategory={handleCategoryUpdate}
              updateFilters={handleUpdateFilters}
              styles={styles}
            />
            :
            <SearchList region={region}
              updateFilters={handleUpdateFilters}
              radius={radius}
              services={services}
              city={city}
              onServiceClick={onServiceClick}
              onMapViewClick={toggleMapView}
              category={category}
              filterButtonsOutline={false}
              setCategory={handleCategoryUpdate} styles={styles.list}/>
          )
          :
          <FiltersPanel region={region} mapView={true} onMapViewClick={toggleMapView}
            styles={styles.map.filtersPanelStyles}
            updateFilters={handleUpdateFilters} radius={radius} city={city}
            category={category}
            setCategory={setCategory}/>
      }
    </View>
  )
}

export default Search

const searchStyles = makeStyles((theme, {isTabletOrSmallerScreen, hideSearchPanelContainer}) => {
  const padding = isTabletOrSmallerScreen ? 10 : 30;

  return {
    list: {
      filtersPanelStyles: {
        container: {
          padding: padding,
          justifyContent: 'space-between',
          flexDirection: 'row',
          flexWrap: 'wrap',
          backgroundColor: 'white',
        },
      },
      serviceListingViewStyle: {
        scrollView: {
          padding: padding,
        },
      },
    } as SearchListStyles,
    map: {
      filtersPanelStyles: {
        container: {
          padding: padding,
          justifyContent: 'space-between',
          flexDirection: 'row',
          flexWrap: 'wrap',
          backgroundColor: 'white',
        },
      },
      outerSearchPanelView: {
        marginHorizontal: padding, borderColor: borderColor,
        borderWidth: hideSearchPanelContainer ? 0 : 1,
        borderRadius: 10,
      },
      searchPanelStyles: {
        container: {},
      },
    } as SearchMapStyles,
  }
});