import {StyleSheet, View} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Text} from '@rneui/themed';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faWheat} from '@fortawesome/pro-solid-svg-icons/faWheat'
import {primaryColor} from '../constants/Colors';
import {companyDescription, companyName} from '../constants/Domain';

const AppLoadingScreen = () => {

  return (
    <SafeAreaProvider style={styles.container}>
      <View style={{
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#ffffff',
      }}>

        <View style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <FontAwesomeIcon icon={faWheat} color={primaryColor} size={60} style={{paddingBottom: 10}}/>
          <View style={{flexDirection: 'column', alignItems: 'center', paddingBottom: 20}}>
            <Text h3 style={{color: primaryColor}}>{companyName}</Text>
            <View style={{padding: 20}}>
              <Text h4 style={{color: primaryColor}}>{companyDescription}</Text>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaProvider>
  );
}

export default AppLoadingScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 40,
  },
});
