export const publicApiUrl = 'https://app.swaggerhub.com/apis/purplegrain/Public';
export const mailToUrl = 'mailto:support@purplegrain.com?subject=Issue';
export const twitterUrl = 'https://twitter.com/purplegrain';
export const instagramUrl = 'https://www.instagram.com/purplegrainapp';
export const linkedinUrl = 'https://www.linkedin.com/company/purplegrain';
export const githubUrl = 'https://github.com/purplegrain'

export const buyMeACoffeeUrl = 'https://www.buymeacoffee.com/purplegrain'
export const googleReviewUrl = 'https://g.page/r/CTk_nKEeVPa4EB0/review'
export const horshamGeolocation = {latitude: -36.71131, longitude: 142.19981}
export const companyName = 'Purplegrain'
export const companyNameStylized = 'purplegrain'
export const companyDescription = 'List, schedule, and book contract agricultural services'

export const pitchDeck = 'https://app.slidebean.com/p/cph9ugidbv/purplegrain-pitch-deck'

export default {
  companyNameStylized,
  publicApiUrl,
  mailToUrl,
  twitterUrl,
  instagramUrl,
  linkedinUrl,
  githubUrl,
  companyDescription,
  companyName,
}