import React, {useRef} from 'react';
import { StyleSheet,View } from 'react-native';
import { Input, Button  } from '@rneui/themed';
import { RootTabScreenProps } from '../../types';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import { sendPasswordResetInstructions } from '../../services/CurrentUserService';
import { sanitizedErrorsToFormik, sanitizeErrors as sanitize, sanitizeErrors } from '../../utils/ErrorUtil';
import { AlertContext } from '../../contexts/AlertProvider';
import * as Yup from 'yup';
import { Formik } from 'formik';
type SanitizedErrors = import('../..').SanitizedErrors;
type ApiErrorResponse = import('../..').ApiErrorResponse;
import ReactGA from 'react-ga4';
import { AUTHENTICATION_CATEGORY, FORGOT_PASSWORD_ACTION } from '../../utils/analyticsUtil';

export default function ForgotPasswordScreen({ navigation, route }: RootTabScreenProps<'ForgotPassword'>) {
  const { toast } = React.useContext(AlertContext);
  const defaultValues = 
    { email: route.params?.email}
  const forgotSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });
  const forgotPasswordAttempts = useRef(0)
  
  return (
    <AuthStackWrapper onPress={()=>{navigation.push('Landing')}}>
      <Formik
        enableReinitialize={true}
        validationSchema={forgotSchema}
        initialValues={defaultValues}
        onSubmit={async (values, {setErrors}) => {

          forgotPasswordAttempts.current += 1;

          ReactGA.event({
            category: AUTHENTICATION_CATEGORY,
            action: FORGOT_PASSWORD_ACTION,
            value: forgotPasswordAttempts.current,
          });

          try {
            await sendPasswordResetInstructions(values.email)
            toast({message: 'Success. Password details sent to provided email', timeout: 6000})
            navigation.push('Login', {email: values.email})
          } catch (err) {
            const sanitizedError = sanitizeErrors(err as Error | ApiErrorResponse) as SanitizedErrors
            
            if (sanitizedError) {
              const formikErrors = sanitizedErrorsToFormik(sanitizedError)
              setErrors(formikErrors)

              if (formikErrors.base) {
                toast({message: formikErrors.base, timeout: 6000})
              }
            } else {
              console.error(err)
            }
          }
        }}
      >
        {({ errors, touched, handleChange, handleSubmit, values }) => (
          <View>
            <Input
              label='Email'
              onChangeText={handleChange('email')}
              value={values.email}
              errorStyle={styles.errors}
              errorMessage={touched.email && errors.email}
              inputContainerStyle={styles.containerStyle}
              onSubmitEditing={()=>{
                if (values.email) {
                  handleSubmit()
                }
              }}
            />
            <View style={{
              flex: 1,
              alignSelf: 'flex-center',
              alignItems: 'center',
            }}>
              <Button
                title="Back to login"
                buttonStyle={styles.clearButton}
                type="clear"
                onPress={()=>{navigation.push('Login', {email: values.email})}}
              />
 
            </View>
            <Button
              title="Reset Password"
              loading={false}
              style={styles.actionButton}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>  
    </AuthStackWrapper>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    borderWidth: 1,
    borderRadius: 5,
  },
  actionButton: {
    marginTop:10,
    minWidth: 200,
  },
  clearButton: {
    justifyContent: 'flex-start',
  },
  errors: {
    color: 'red',
  },
});