import React, {useCallback, useContext} from 'react';
import {ScrollView, TouchableOpacity} from 'react-native';
import HomeStackWrapper from '../../components/homeStack/HomeStackWrapper';
import {RootTabScreenProps} from '../../types';
import {Card, Text, useTheme} from '@rneui/themed';
import {useIntercom} from 'react-use-intercom';
import {AuthContext} from '../../contexts/AuthProvider';
import {useFocusEffect} from '@react-navigation/native';
import {settingsStyles} from './SettingsScreen';
import {SupportContext} from '../../contexts/SupportProvider';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

export default function SupportScreen({navigation}: RootTabScreenProps<'Support'>) {
  const {theme} = useTheme()
  const iconSize = 20;

  const {boot, shutdown, hide, show, update, isOpen} = useIntercom();

  const {state} = useContext(AuthContext)
  const {currentUser} = state

  const styles = settingsStyles()
  const {state: SupportState} = useContext(SupportContext)
  const {intercomVisible} = SupportState

  useFocusEffect(useCallback(() => {
    boot()

    update({
      hideDefaultLauncher: false,
      name: currentUser!.attributes.name,
      email: currentUser!.attributes.email,
      createdAt: currentUser!.attributes.createdAt,
    })

    if (!isOpen && intercomVisible) {
      show()
    } else if (isOpen && !intercomVisible) {
      hide()
    }

    return () => {
      hide()
      update({hideDefaultLauncher: true});
    }
  }, []))

  return (
    <HomeStackWrapper navigation={navigation}>
      <ScrollView horizontal={false} style={styles.container}>
        <TouchableOpacity onPress={() => show()}>
          <Card containerStyle={styles.cardContainer}>
            <Card.Title style={styles.cardTitle}>
              <Icon
                name="face-agent"
                color={theme.colors.grey2}
                size={iconSize}
                style={styles.cardTitleIcon}/>

              Support
            </Card.Title>
            <Text style={styles.cardText}>
              Are you experiencing an issue or would you like to request a feature? Our team is hear to help.
            </Text>
            <Text style={styles.cardLink}>Request support</Text>
          </Card>
        </TouchableOpacity>
      </ScrollView>
    </HomeStackWrapper>
  );
}