import React, {useCallback, useContext} from 'react';
import {ScrollView, TouchableOpacity, View} from 'react-native';
import HomeStackWrapper from '../../components/homeStack/HomeStackWrapper';
import {RootTabScreenProps} from '../../types';

import {Button, Card, useTheme} from '@rneui/themed';
import {useGetServicesByOwnerQuery} from '../../features/service/serviceApi';
import ShowServiceCard from '../../components/services/ShowServiceCard';
import {AuthContext} from '../../contexts/AuthProvider';
import {EntitiesAdapterResult} from '../..';
import {useFocusEffect} from '@react-navigation/native';
import {windowDimensions} from '../../hooks/windowHooks';

type Service = import('../../features/service/serviceSlice').Service;


export default function SettingsScreen({navigation}: RootTabScreenProps<'ServicesIndex'>) {
  const {theme} = useTheme()
  const {isPhoneOrSmallerScreen, phoneWidth, width} = windowDimensions();
  const {state} = useContext(AuthContext)
  const {currentUser} = state;
  const {
    data: servicesForUser,
    error,
    isLoading,
    isSuccess,
    refetch,
  }: { data: EntitiesAdapterResult<Service>, error: any, isLoading: boolean, isSuccess: boolean } = useGetServicesByOwnerQuery(currentUser);

  const containerWidth = isPhoneOrSmallerScreen ? width : phoneWidth;

  useFocusEffect(useCallback(() => {
    refetch()
  }, []))

  return (
    <HomeStackWrapper styles={{container: {paddingBottom: 20}}}>
      <ScrollView horizontal={false}>
        {isSuccess &&
          <View>
            {servicesForUser.ids.length ?
              servicesForUser.ids.map((id) => (
                <TouchableOpacity key={id} onPress={() => navigation.navigate('ServicesShow', {
                  id: id,
                  slug: servicesForUser.entities[id].attributes.slug,
                })} style={{maxWidth: containerWidth}}>
                  <Card>
                    <ShowServiceCard service={servicesForUser.entities[id] as Service} imageSize={400}
                      navigation={navigation}/>
                  </Card>
                </TouchableOpacity>
              )) :
              <Button type="outline" title="Create Your First Service" onPress={() => {
                navigation.navigate('ServicesNew')
              }}/>
            }
          </View>
        }
      </ScrollView>
    </HomeStackWrapper>
  );
}

