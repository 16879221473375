import React, {createContext, useMemo } from 'react';
import AwesomeAlert from 'react-native-awesome-alerts';

export const AUTH_TOKEN_KEY = 'auth-context-token';

export type AlertReturnData = {
    cancel?: boolean,
    confirm?: boolean,
    dismiss?: boolean,
}

export type AlertData = {
    show: boolean;
    title: string;
    message: string;
    closeOnTouchOutside: boolean;
    closeOnHardwareBackPress: boolean;
    showCancelButton: boolean;
    showConfirmButton: boolean;
    cancelText: string;
    confirmText: string;
    confirmButtonColor: string;
    cancelButtonColor: string;
    timeout: number;
};

export type AlertContextData = {
  state: AlertData;
  toast(props: Partial<AlertData>): Promise<void>;
  alert(props: Partial<AlertData>): Promise<AlertReturnData>;
  clear(): Promise<void>;
};

const mergeWithDefault = (props: Partial<AlertData> = {}): Partial<AlertData> => {
  return Object.assign({}, {
    showProgress: false,
    title: null,
    message: null,
    closeOnTouchOutside: true,
    closeOnHardwareBackPress: true,
    showCancelButton: true,
    showConfirmButton: true,
    cancelText: null,
    confirmText: null,
    confirmButtonColor: '#DD6B55',
    cancelButtonColor: '#DD6B55',
    onCancelPressed: ()=> {},
    onConfirmPressed: ()=> {},
    onDismiss: ()=>{},
  }, props)
}


export const AlertContext = createContext<AlertContextData>({} as AlertContextData);

export const AlertProvider: React.FC = ({children}) => {
  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case 'CLEAR':
          return Object.assign({}, prevState, action)
        case 'SHOW':
          return Object.assign({}, prevState, action)
      }
    },
    mergeWithDefault({
      show: false,
    }),
  );
  
  const alertContext = useMemo(
    () => ({
      state,
      toast: (props: Partial<AlertData>): Promise<void> => {
        const delay = props.timeout || 4000
        return new Promise((resolve)=> {
          const newProps = Object.assign({}, mergeWithDefault(props), {showConfirmButton: false, showCancelButton: false})
          dispatch({...newProps, type: 'SHOW', show: true})
          setTimeout(()=>{
            dispatch({type: 'CLEAR', show: false})
            resolve()
          }, delay)
        })
      },
      alert: async (props: Partial<AlertData>): Promise<AlertReturnData> => {
        return new Promise((resolve)=> {
          dispatch(Object.assign({}, mergeWithDefault(props), {type: 'SHOW', show: true, 
            onCancelPressed: ()=> {
              dispatch({type: 'CLEAR', show: false})
              resolve({cancel: true});
            },
            onConfirmPressed: ()=>{
              dispatch({type: 'CLEAR', show: false})
              resolve({confirm: true})
            },
            onDismiss: ()=> {
              dispatch({type: 'CLEAR', show: false})
              resolve({dismiss: true})},
          }))
        })
      },
      clear: () => {
        return dispatch({type: 'CLEAR', show: false})
      },
    }),
    [],
  );

  return (
    <AlertContext.Provider value={alertContext}>
      {children}
      <AwesomeAlert
        show={state.show}
        showProgress={false}
        title={state.title}
        message={state.message}
        closeOnTouchOutside={state.closeOnTouchOutside}
        closeOnHardwareBackPress={state.closeOnHardwareBackPress}
        showCancelButton={state.showCancelButton}
        showConfirmButton={state.showConfirmButton}
        cancelText={state.cancelText}
        confirmText={state.confirmText}
        confirmButtonColor={state.confirmButtonColor}
        onCancelPressed={state.onCancelPressed}
        onConfirmPressed={state.onConfirmPressed}
      />
    </AlertContext.Provider>
  );
};

export default {
  AlertContext, AlertProvider,
}