import Crypto from 'crypto';
import getEnvVars from '../environment'
import {Nullable} from '../index';

const {clientUrl} = getEnvVars();

export const slugRegEx = /[a-zA-Z0-9\-_]+/
export const nonSlugRegEx = /[^a-zA-Z0-9\-_]+/
export const nonStartSlugRegEx = /^[\-_]+/

export const randomSalt = (length = 10) => {
  return Crypto.randomBytes(length).toString('hex').slice(length);
}

export const sanitizeSlug = (slug: string, maxLength = 255) => {
  // /\A[\p{L}\p{Nd}\-_]+\Z/
  if (typeof slug !== 'string') {
    return '';
  }
  let sanitizedSlug = slug

  /* Space -> hyphen */
  sanitizedSlug = sanitizedSlug.replace(/\s/, '-')

  /* Uppercase -> lower */
  sanitizedSlug = sanitizedSlug.toLowerCase()

  /* Remove double hyphen or underscore */
  sanitizedSlug = sanitizedSlug.replace(/__/, '_').replace(/--/, '-')

  /* Remove invalid characters */
  sanitizedSlug = sanitizedSlug.replace(nonSlugRegEx, '')

  /* Invalid start character */
  sanitizedSlug = sanitizedSlug.replace(nonStartSlugRegEx, '')

  /* Max Length */
  sanitizedSlug = sanitizedSlug.slice(0, maxLength)

  return sanitizedSlug;
}

export const serviceUrl = ({id, slug}: { id: string, slug?: string }) => {
  return [clientUrl, 'services', id, slug].join('/')
}

export const mailToUrl = (email: string, subject: Nullable<string>) => {
  return `mailto:${email}?subject=${subject}`
}

export const telToUrl = (phone: string) => {
  return `tel:${phone}`
}

export const smsToUrl = (mobile: string) => {
  return `sms:${mobile}`
}
export default {
  sanitizeSlug,
  randomSalt,
  serviceUrl,
  mailToUrl,
  telToUrl,
  smsToUrl,
}