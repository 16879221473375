
/* Google Analytics Constants */
export const AUTHENTICATION_CATEGORY = 'authentication'
export const FORGOT_PASSWORD_ACTION = 'forgot-password'
export const LOGIN_ACTION = 'login'
export const REGISTER_ACTION = 'register'
export const RESET_PASSWORD_ACTION = 'reset-password'
export const SEND_CONFIRMATION_ACTION = 'send-confirmation'
export const CONFIRMATION_ACTION = 'confirmation'

export default { CONFIRMATION_ACTION, SEND_CONFIRMATION_ACTION, RESET_PASSWORD_ACTION, REGISTER_ACTION, LOGIN_ACTION, AUTHENTICATION_CATEGORY, FORGOT_PASSWORD_ACTION }