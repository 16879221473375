import {StatusBar} from 'expo-status-bar';
import {SafeAreaProvider} from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation/Index';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {createTheme, ThemeProvider} from '@rneui/themed';
import AppLoadingScreen from './screens/AppLoadingScreen';
import {AuthProvider} from './contexts/AuthProvider';
import {AlertProvider} from './contexts/AlertProvider';
import 'react-native-gesture-handler'; // https://reactnavigation.org/docs/drawer-navigator
import * as Sentry from 'sentry-expo';
import getEnvVars from './environment';
import ReactGA from 'react-ga4';
import {GeoLocationProvider} from './contexts/GeoLocationProvider';
import {primaryColor, secondaryColor} from './constants/Colors';
import {SupportProvider} from './contexts/SupportProvider';

const {sentryDns, environment, tracesSampleRate, googleAnalyticsId} = getEnvVars();

Sentry.Browser.init({
  dsn: sentryDns,
  environment,
  tracesSampleRate,
});

ReactGA.initialize(googleAnalyticsId);

const App = () => {
  const isLoadingComplete = useCachedResources();

  const myTheme = createTheme({
    mode: 'light',
    lightColors: {
      primary: primaryColor,
      secondary: secondaryColor,
    },
    components: {
      Card: {
        containerStyle: {
          backgroundColor: 'white',
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#D8D8D8',
          paddingVertical: 20,
          paddingHorizontal: 20,
          marginVertical: 20,
        },
      },
      Button: {
        buttonStyle: {
          borderRadius: 10,
        },
      },
      Input: {
        inputContainerStyle: {
          borderWidth: 1,
          borderRadius: 8,
        },
        labelStyle: {
          color: 'gray',
          fontWeight: 500,
          fontSize: 14,
        },
        inputStyle: {
          paddingHorizontal: 10,
        },
        containerStyle: {
          paddingHorizontal: 0,
        },
      },
    },
  })

  if (!isLoadingComplete) {
    return (
      <AppLoadingScreen/>
    )
  } else {
    return (
      <SafeAreaProvider style={{backgroundColor: 'white'}}>
        <ThemeProvider theme={myTheme}>
          <Provider store={store}>
            <AlertProvider>
              <AuthProvider>
                <GeoLocationProvider>
                  <SupportProvider>
                    <Navigation/>
                    <StatusBar/>
                  </SupportProvider>
                </GeoLocationProvider>
              </AuthProvider>
            </AlertProvider>
          </Provider>
        </ThemeProvider>
      </SafeAreaProvider>
    );
  }
}


export default App