
import { apiSlice } from '../../services/apiSlice'
import normalize from 'json-api-normalizer';
import { City } from './citySlice';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntitiesAdapterResult } from '../..';

const normalizeApiResponse = (response: any) => {
  const data = Object.assign({}, normalize(response, { })).city
  
  return [undefined, null].includes(data) ? [] : Object.keys(data).map((key) => { return data[key] }) as Array<City>
}
  
const cityAdapter = createEntityAdapter<City>({})
const initialState = cityAdapter.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCityById: builder.query<EntitiesAdapterResult<City>, string>({
      query: (id: string) => ({url: `cities/${id}`}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as City
        return cityAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({ type: 'City', id } as const)),
      ],
    }),
    getCityByPlace: builder.query<EntitiesAdapterResult<City>, string>({
      query: (placeId: string) => ({url: `cities/place?place_id=${placeId}`}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as City
        return cityAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({ type: 'City', id } as const)),
      ],
    }),
    getCityByIp: builder.query<EntitiesAdapterResult<City>, void>({
      query: () => ({url: 'cities/ip'}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as City
        return cityAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({ type: 'City', id } as const)),
      ],
    }),
  }),
})
  
export const { useGetCityByIpQuery, useGetCityByPlaceQuery, useGetCityByIdQuery, useLazyGetCityByIpQuery, useLazyGetCityByPlaceQuery, useLazyGetCityByIdQuery } = extendedApiSlice