import { StyleSheet, View } from 'react-native';
import { Input, Button  } from '@rneui/themed';
import { RootTabScreenProps } from '../../types';
import { sendConfirmation as handleSendConfirmation } from '../../services/CurrentUserService';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import { sanitizedErrorsToFormik, sanitizeErrors } from '../../utils/ErrorUtil';
import { ApiErrorResponse, SanitizedErrors } from '../..';
import { AlertContext } from '../../contexts/AlertProvider';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React, {useRef} from 'react';
import ReactGA from 'react-ga4';
import { AUTHENTICATION_CATEGORY, SEND_CONFIRMATION_ACTION } from '../../utils/analyticsUtil';

export default function SendConfirmationScreen({ navigation, route }: RootTabScreenProps<'SendConfirmaiton'>) {
  const { toast } = React.useContext(AlertContext);
  const defaultValues = 
    { email: route.params?.email}
  const confirmationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });
  const sendConfirmationAttempts = useRef(0)

  return (
    <AuthStackWrapper onPress={()=>{navigation.push('Landing')}}>
      <Formik
        enableReinitialize={true}
        validationSchema={confirmationSchema}
        initialValues={defaultValues}
        onSubmit={async (values, {setErrors}) => {

          sendConfirmationAttempts.current += 1;

          ReactGA.event({
            category: AUTHENTICATION_CATEGORY,
            action: SEND_CONFIRMATION_ACTION,
            value: sendConfirmationAttempts.current,
          });

          try {
            await handleSendConfirmation(values.email);
            toast({message: 'Success. Confirmation details sent to provided email', timeout: 6000})
            navigation.push('Login', {email: values.email})
          } catch (err) {
            const sanitizedError = sanitizeErrors(err as Error | ApiErrorResponse) as SanitizedErrors
            
            if (sanitizedError) {
              const formikErrors = sanitizedErrorsToFormik(sanitizedError)
              setErrors(formikErrors)

              if (formikErrors.base) {
                toast({message: formikErrors.base, timeout: 6000})
              }
            } else {
              console.error(err)
            }
          }
        }}
      >
        {({ errors, touched, handleChange, handleSubmit, values }) => (
          <View>
            <Input
              label='Resend Email'
              onChangeText={handleChange('email')}
              value={values.email}
              errorStyle={styles.errors}
              errorMessage={touched.email && errors.email}
              inputContainerStyle={styles.containerStyle}
              onSubmitEditing={()=>{
                if (values.email) {
                  handleSubmit()
                }
              }}
            />
            <View style={{
              flex: 1,
              alignSelf: 'flex-center',
              alignItems: 'center',
            }}>
              <Button
                title="Back to login"
                buttonStyle={styles.clearButton}
                type="clear"
                onPress={()=>{navigation.push('Login', {email: values.email})}}
              />
 
            </View>
            <Button
              title="Send Confirmation"
              loading={false}
              style={styles.actionButton}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>
    </AuthStackWrapper>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    borderWidth: 1,
    borderRadius: 5,
  },
  actionButton: {
    marginTop: 10,
    minWidth: 200,
  },
  clearButton: {
    justifyContent: 'flex-start',
  },
  errors: {
    color: 'red',
  },
});