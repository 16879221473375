import {Input, Switch, Text} from '@rneui/themed'
import React from 'react';
import {ContactInfo} from '../../index';
import {View} from 'react-native';

const UpdateContactInfo = ({
  contactInfo,
  setFieldValue,
  setFieldTouched,
}: { contactInfo: ContactInfo, setFieldValue: Function, setFieldTouched: Function }) => {

  const handleUpdate = (value: ContactInfo) => {
    setFieldValue('contactInfo', value);
    setFieldTouched('contactInfo');
  }

  const handleInputUpdate = (name: string, value: string) => {
    if (value == undefined || contactInfo[name] == value || (!contactInfo[name] && !value)) {
      return
    }

    const obj = {} as ContactInfo
    obj[name] = value || null
    const newContactInfo = Object.assign({}, contactInfo, obj) as ContactInfo
    void handleUpdate(newContactInfo)
  }

  return (
    <View style={{backgroundColor: 'white'}}>
      <Input
        label="Mobile"
        onChangeText={(value) => {
          handleInputUpdate('mobile', value)
        }}
        value={contactInfo.mobile || ''}
      />
      <Input
        label="Phone"
        onChangeText={(value) => {
          handleInputUpdate('phone', value)
        }}
        value={contactInfo.phone || ''}
      />
      <Input
        label="Facebook"
        onChangeText={(value) => {
          handleInputUpdate('facebook', value)
        }}
        value={contactInfo.facebook || ''}
      />
      <Input
        label="Url"
        onChangeText={(value) => {
          handleInputUpdate('url', value)
        }}
        value={contactInfo.url || ''}
      />
      <View style={{flexDirection: 'row', paddingTop: 20, backgroundColor: 'white'}}>
        <Switch value={!!contactInfo.email}
          onValueChange={(value: boolean) => {
            const newContactInfo = Object.assign({}, contactInfo, {email: value}) as ContactInfo
            void handleUpdate(newContactInfo)
          }}/>
        <Text style={{color: '#808080', fontWeight: '500', paddingLeft: 10}}>Display Email?</Text>
      </View>
    </View>
  )
}

export default UpdateContactInfo