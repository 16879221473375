/*****************************
 * environment.js
 * path: '/environment.js' (root of your project)
 ******************************/

import Constants from 'expo-constants';

// TODO: move to process variables
const sentryDns = 'https://239d1b060f8b4b63a3d68131b2608561@o1236957.ingest.sentry.io/6387302',
  googleApiKey = 'AIzaSyAdzlr19C9GcbxHkE7KeUsKEZNGF5gYZac',
  googleAnalyticsId = 'G-WKB7VLWJ1Q',
  googleRecaptchaEnterpriseId = '6Lf7gfYkAAAAAHqIhYYWHVuKZSJOkpzQshB0SY0r',
  googleRecaptchaId = '6Lf7gfYkAAAAAAgoDifciPwzY5FQs08bc9QUAmLR',
  intercomUrl = 'https://api-iam.intercom.io',
  intercomId = 'akfa1btm';

export const ENV = {
  image_upload_size: 52428800,
  development: {
    apiBaseUrl: 'http://localhost:3000',
    apiVersionPath: '/api/v1/',
    apiUsersPath: '/api/v1/users/',
    clientUrl: 'http://localhost:19006',
    googleApiKey,
    sentryDns,
    googleAnalyticsId,
    googleRecaptchaEnterpriseId,
    googleRecaptchaId,
    intercomUrl,
    intercomId,
    environment: 'development',
    tracesSampleRate: 1.0,
  },
  production: {
    apiBaseUrl: 'https://api.purplegrain.com',
    apiVersionPath: '/api/v1/',
    apiUsersPath: '/api/v1/users/',
    clientUrl: 'https://purplegrain.com',
    googleApiKey,
    sentryDns,
    googleAnalyticsId,
    googleRecaptchaEnterpriseId,
    googleRecaptchaId,
    intercomUrl,
    intercomId,
    environment: 'production',
    tracesSampleRate: 0.5,
  },
};

const getEnvVars = (env = Constants.manifest.releaseChannel) => {
  // What is __DEV__ ?
  // This variable is set to true when react-native is running in Dev mode.
  // __DEV__ is true when run locally, but false when published.
  if (__DEV__) {
    return ENV.development
  } else {
    return ENV.production;
  }
};

export default getEnvVars;