import {useContext, useEffect, useState} from 'react';
import {GooglePlaceData, GooglePlaceDetail, GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import {extendedApiSlice as extendedCityApiSlice} from '../features/city/cityApi';
import {AuthContext} from '../contexts/AuthProvider';
import {store} from '../store/store';
import {City} from '../features/city/citySlice';
import {EntitiesAdapterResult, Nullable} from '..';
import {makeStyles, Text, useTheme} from '@rneui/themed';
import getEnvVars from '../environment';
import {View} from 'react-native';

const {googleApiKey, apiBaseUrl, apiVersionPath} = getEnvVars();


export const AUTO_COMPLETE_PATH = 'google-places/autocomplete'
export const DEFAULT_LOCATION_STRING = 'Default Location'
export const cityLocationString = (city: Nullable<City>): string => {

  if (!city) {
    return DEFAULT_LOCATION_STRING
  } else {
    return [city.attributes.name, city.attributes.subdivision, city.attributes.country].join(', ')
  }
}

export default function CityAutocomplete({
  id,
  onUpdate,
  styles = {},
  options = {},
  label = '',
}: { id: Nullable<string>, onUpdate: any, styles?: any, options?: Record<string, string | number>, label: string }) {
  const [cityPlaceHolder, setCityResult] = useState(DEFAULT_LOCATION_STRING)
  const autocompleteOptions = Object.assign({}, {
    minLength: 3,
    numberOfLines: 10,
    isRowScrollable: true,
    debounce: 500,
  }, options)
  const autocompleteStyles = Object.assign({}, useStyles(), styles)
  const {state} = useContext(AuthContext)
  const {token} = state;
  const {theme} = useTheme();

  useEffect(() => {
    if (!id) {
      return
    }

    store.dispatch(extendedCityApiSlice.endpoints.getCityById.initiate(id)).then(({data}) => {
      if (data) {
        const {ids, entities} = data;
        const city = entities[ids[0]] as City
        setCityResult(cityLocationString(city))
      }
    }).catch(console.error)

  }, [id])

  const autoCompleteOnPress = async (placeData: GooglePlaceData, detail: GooglePlaceDetail | null = null): Promise<void> => {

    if (!placeData) {
      onUpdate(null)
    }

    const {data} = await store.dispatch(extendedCityApiSlice.endpoints.getCityByPlace.initiate(placeData['place_id']))

    if (!data) {
      throw Error('invalid city')
    }

    const {ids, entities} = data as EntitiesAdapterResult<City>
    const city = entities[ids[0]] as City

    onUpdate(city)
  }


  return (
    <View style={[autocompleteStyles.view, {width: '100%'}]}>
      <Text style={{color: '#808080', fontWeight: '500'}}>{label}</Text>
      <GooglePlacesAutocomplete
        minLength={autocompleteOptions.minLength}
        numberOfLines={autocompleteOptions.numberOfLines}
        isRowScrollable={autocompleteOptions.isRowScrollable}
        placeholder={cityPlaceHolder}
        debounce={autocompleteOptions.debounce}
        onPress={autoCompleteOnPress}
        query={{
          key: googleApiKey,
          language: 'en',
        }}
        requestUrl={{
          url: [apiBaseUrl, apiVersionPath, AUTO_COMPLETE_PATH].join('/'),
          useOnPlatform: 'web',
          headers: {
            Authorization: `Bearer ${token}`, // if required for your proxy
          },
        }}
        styles={autocompleteStyles}
      />
    </View>
  );
}


const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: theme.colors.white,
  },
  textInput: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#86939E',
  },
  listView: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#86939E',
  },
}));