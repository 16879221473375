import React, {useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import {Button, Input, useTheme} from '@rneui/themed';
import {RootTabScreenProps, SanitizedErrors} from '../../types';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import {sanitizedErrorsToFormik, sanitizeErrors} from '../../utils/ErrorUtil';
import {AuthContext} from '../../contexts/AuthProvider';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {AlertContext} from '../../contexts/AlertProvider';
import ReactGA from 'react-ga4';
import {AUTHENTICATION_CATEGORY, LOGIN_ACTION} from '../../utils/analyticsUtil';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {useTogglePasswordVisibility} from '../../hooks/formfieldHooks';
import {ApiErrorResponse} from '../../index';

export default function LoginScreen({route, navigation}: RootTabScreenProps<'Login'>) {
  const {signIn} = React.useContext(AuthContext);
  const {toast} = React.useContext(AlertContext);
  const defaultValues =
    {email: route.params?.email || '', password: ''}
  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const loginAttempts = useRef(0)

  const {passwordVisibility, rightIcon, handlePasswordVisibility} = useTogglePasswordVisibility();
  const {theme} = useTheme()

  return (
    <AuthStackWrapper onPress={() => {
      navigation.push('Landing')
    }}>
      <Formik
        enableReinitialize={true}
        validationSchema={loginSchema}
        initialValues={defaultValues}
        onSubmit={async (values, {setErrors}) => {

          loginAttempts.current += 1;

          ReactGA.event({
            category: AUTHENTICATION_CATEGORY,
            action: LOGIN_ACTION,
            value: loginAttempts.current,
          });

          try {
            await signIn(values.email, values.password)
          } catch (err) {
            const sanitizedError = sanitizeErrors(err as Error | ApiErrorResponse) as SanitizedErrors

            if (sanitizedError) {
              const formikErrors = sanitizedErrorsToFormik(sanitizedError)
              setErrors(formikErrors)

              if (formikErrors.base) {
                toast({message: formikErrors.base, timeout: 6000})
              }
            } else {
              console.error(err)
            }
          }
        }}
      >
        {({errors, touched, handleChange, handleSubmit, values}) => (
          <View>
            <Input
              label="Email"
              onChangeText={handleChange('email')}
              value={values.email}
              errorStyle={styles.errors}
              errorMessage={touched.email && errors.email}
              inputContainerStyle={styles.containerStyle}
            />

            <Input
              label="Password"
              rightIcon={<MaterialCommunityIcons name={rightIcon} size={20} onPress={handlePasswordVisibility}
                color={theme.colors.grey2}/>}
              rightIconContainerStyle={{marginVertical: 0}}
              onChangeText={handleChange('password')}
              secureTextEntry={passwordVisibility}
              value={values.password}
              errorStyle={styles.errors}
              errorMessage={touched.password && errors.password}
              inputContainerStyle={styles.containerStyle}
              onSubmitEditing={() => {
                if (values.email && values.password) {
                  handleSubmit()
                }
              }}
            />

            <View style={{
              flexDirection: 'row',
              alignSelf: 'flex-center',
              flexWrap: 'wrap',
            }}>
              <Button
                title="Register"
                type="clear"
                buttonStyle={styles.clearButton}
                onPress={() => {
                  navigation.push('Register', {email: values.email})
                }}
              />
              <Button
                title="Forgot Password"
                buttonStyle={styles.clearButton}
                type="clear"
                onPress={() => {
                  navigation.push('ForgotPassword', {email: values.email})
                }}
              />
              <Button
                title="Resend Confirmation"
                type="clear"
                buttonStyle={styles.clearButton}
                onPress={() => {
                  navigation.push('SendConfirmation', {email: values.email})
                }}
              />
            </View>
            <Button
              title="Log in"
              loading={false}
              style={styles.actionButton}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>
    </AuthStackWrapper>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    borderWidth: 1,
    borderRadius: 5,
  },
  actionButton: {
    margin: 10,
    minWidth: 200,
  },
  clearButton: {
    justifyContent: 'flex-start',
  },
  errors: {
    color: 'red',
  },
});