import {useEffect, useState} from 'react';
import {EntitiesAdapterResult} from '../index';
import {Category} from '../features/category/categorySlice';
import {useGetCategoriesQuery} from '../features/category/categoryApi';

export const categoryHooks = () => {
  const [categories, setCategories] = useState<Array<Category>>([]);
  const {
    data: entityAdapterResult,
    error,
    isLoading,
    isSuccess,
    refetch,
  }: { data: EntitiesAdapterResult<Category>, error: any, isLoading: boolean, isSuccess: boolean } = useGetCategoriesQuery();

  useEffect(() => {
    if (entityAdapterResult?.ids) {
      const updatedCategories = entityAdapterResult.ids.map((id) => {
        return entityAdapterResult.entities[id]
      })
      setCategories(updatedCategories)
    }
  }, [isSuccess])

  return {
    categories,
  };
}