import {useContext, useEffect} from 'react';
import {GeoLocationContext, GeoLocationData} from '../contexts/GeoLocationProvider';
import {Coordinate} from '../index';

export const geoLocationHooks = () => {
  const {state, setFromBrowser, set} = useContext(GeoLocationContext)
  const {coordinate} = state as GeoLocationData

  useEffect(() => {
    if (!coordinate) {
      setFromBrowser(navigator)
    }
  }, [coordinate])

  const setCoordinate = (coordinate: Coordinate) => {
    void set(coordinate);
  }

  return {
    setCoordinate,
    coordinate,
    setFromBrowser,
  };
}