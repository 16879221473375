import {Service} from '../../features/service/serviceSlice'
import {ScrollView, ViewStyle} from 'react-native';
import React from 'react';
import {makeStyles} from '@rneui/themed';
import ServiceListing, {ServiceListingStyles} from './searchPanel/ServiceListing';
import {windowDimensions} from '../../hooks/windowHooks';

export type ServiceListingViewStyles = { scrollView: ViewStyle }

export const ServiceListingView = ({
  services,
  onServiceClick,
  styles = {scrollView: {}},
}: { styles: ServiceListingViewStyles, services: Service[], onServiceClick: Function }) => {

  const {isPhoneOrSmallerScreen, width} = windowDimensions();
  const size = isPhoneOrSmallerScreen ? (width - 50) : 250;

  const containerStyles = useStyles({isPhoneOrSmallerScreen, scrollView: styles.scrollView})
  const serviceListingStyle = serviceListingStyles();

  return (
    <ScrollView horizontal={false} pointerEvents="auto" contentContainerStyle={containerStyles.scrollView}>
      {services.map((service, i) => (
        <ServiceListing key={i}
          service={service}
          avatarSize={size}
          infoBoxSize={size}
          styles={serviceListingStyle}
          onPress={() => {
            onServiceClick(service)
          }}/>
      ))}
    </ScrollView>
  )
}

const useStyles = makeStyles((theme, props: { isPhoneOrSmallerScreen: boolean, scrollView: ViewStyle }) => {
  const scrollView = props.isPhoneOrSmallerScreen ? {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: 5,
  } : {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 10,
  }

  return {
    scrollView: Object.assign(scrollView, props.scrollView),
  }
});

const serviceListingStyles = makeStyles((theme) => {
  return {
    view: {
      padding: 10,
      backgroundColor: theme.colors.white,
    },
    infoBox: {
      backgroundColor: theme.colors.white,
      padding: 10,
    },
    avatar: {
      borderRadius: 10,
    },
    location: {
      flexWrap: 'wrap',
      textDecorationLine: 'underline',
    },
    title: {
      color: theme.colors.grey0,
      flexWrap: 'wrap',
    },
    cost: {
      paddingTop: 15,
    },
  } as ServiceListingStyles
});