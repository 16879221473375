import React, {useContext, useEffect, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {Button, Card, useTheme} from '@rneui/themed';
import {extendedApiSlice, useGetServiceByIdQuery} from '../../../features/service/serviceApi';
import HomeStackWrapper from '../../../components/homeStack/HomeStackWrapper';
import EditServiceCard from '../../../components/services/EditServiceCard';
import {store} from '../../../store/store';
import * as ImagePicker from 'expo-image-picker';
import {ENV} from '../../../environment';
import extName from 'ext-name';
import {ApiErrorResponse, EntitiesAdapterResult, Nullable, SanitizedErrors} from '../../..';
import {Formik} from 'formik';
import {Service, serviceSchema} from '../../../features/service/serviceSlice';
import {City} from '../../../features/city/citySlice';
import {AuthContext} from '../../../contexts/AuthProvider';
import ShowServiceCard from '../../../components/services/ShowServiceCard';
import {AlertContext} from '../../../contexts/AlertProvider';
import {sanitizedErrorsToFormik, sanitizeErrors} from '../../../utils/ErrorUtil';
import {Category} from '../../../features/category/categorySlice';
import {dataURItoBlob} from '../../../utils/FileUtils';
import {windowDimensions} from '../../../hooks/windowHooks';

export default function ShowScreen({navigation, route}) {
  const {theme} = useTheme()
  const [service, setService] = useState<Nullable<Service>>(null);
  const {state} = useContext(AuthContext)
  const {currentUser} = state;
  const {alert, toast} = React.useContext(AlertContext);
  const {phoneWidth} = windowDimensions();

  const {
    data,
    error,
    isError,
    isLoading,
    isSuccess,
  }: { data: EntitiesAdapterResult<Service>, error: any, isLoading: boolean, isSuccess: boolean } = useGetServiceByIdQuery(route.params.id);

  const removeImage = async (service: Service) => {
    const formData = new FormData();
    await store.dispatch(extendedApiSlice.endpoints.updateImage.initiate({service, formData}))

  }
  const updateImage = async (service: Service) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      base64: false,
    });

    if (!result.cancelled) {

      let blob: Nullable<Blob> = null;

      if (typeof result.uri == 'string') {
        blob = dataURItoBlob(result.uri)
      } else {
        const response = await fetch(result.uri)
        blob = await response.blob()
      }

      if (!blob) {
        return toast({message: 'Could not upload Image.', timeout: 10000})
      } else if (blob.size > ENV.image_upload_size) {
        return toast({message: 'Image too large. Please resize before uploading.', timeout: 10000})
      }

      const mime = extName.mime(blob.type)[0]

      const file = new File([blob], `image.${mime.ext}`, {type: blob.type});

      const formData = new FormData();
      formData.append('image', file)

      await store.dispatch(extendedApiSlice.endpoints.updateImage.initiate({service, formData}))
    }
  }

  const handleCityUpdate = (city: Nullable<City>) => {
    if (!service) {
      return
    }
    const relationships = Object.assign({}, service.relationships, {city: {data: {id: city?.id || null, type: 'city'}}})
    setService(Object.assign({}, service, {relationships: relationships}))
  }

  const handleCategoryUpdate = (category: Nullable<Category>) => {
    if (!service) {
      return
    }
    const relationships = Object.assign({}, service.relationships, {
      category: {
        data: {
          id: category?.id || null,
          type: 'category',
        },
      },
    })
    setService(Object.assign({}, service, {relationships: relationships}))
  }

  const handleDelete = async () => {
    const response = await alert({
      title: `Remove ${service?.attributes.name}`,
      message: 'This action cannot be undone',
      cancelText: 'Cancel',
      confirmText: 'Remove',
    })

    if (response.confirm && service) {
      await store.dispatch(extendedApiSlice.endpoints.deleteService.initiate(service))
      navigation.navigate('Services')
    }
  }

  useEffect(() => {

    if (data) {
      const {ids, entities} = data
      setService(entities[ids[0]] as Service)
    }
  }, [isSuccess, isLoading, isError, data])

  const handleErrors = (errors, setErrors) => {
    const sanitizedError = sanitizeErrors(errors as Error | ApiErrorResponse) as SanitizedErrors

    if (sanitizedError) {
      const formikErrors = sanitizedErrorsToFormik(sanitizedError)
      setErrors(formikErrors)

      if (formikErrors.base) {
        toast({message: formikErrors.base, timeout: 10000})
      }
    } else {
      console.error(errors)
    }
  }

  return (
    <HomeStackWrapper navigation={navigation}>
      <ScrollView horizontal={false} style={{padding: 10}}>


        {service && (currentUser?.id == service.relationships.owner.data?.id ? (
          <Formik
            enableReinitialize={true}
            initialValues={{...service.attributes}}
            validationSchema={serviceSchema}
            onSubmit={async (values, {setSubmitting, setErrors}) => {

              if (!service?.attributes) {
                return
              }

              const attributes = Object.assign({}, service.attributes, values)
              const updatedService = Object.assign({}, service, {attributes})

              try {
                const {
                  data,
                  error,
                } = await store.dispatch(extendedApiSlice.endpoints.updateService.initiate(updatedService))

                if (error) {
                  handleErrors(error.data.errors, setErrors)
                } else {
                  void toast({message: 'Updated Service'})
                  navigation.setParams({slug: values.slug})
                }
              } catch (error) {
                handleErrors(error, setErrors)
              } finally {
                setSubmitting(false)
              }

            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <View style={{maxWidth: phoneWidth}}>
                <EditServiceCard id={service!.id} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched}
                  values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors}
                  touched={touched} relationships={service?.relationships}

                  handleCityUpdate={(city: Nullable<City>) => {
                    setFieldTouched('name')
                    handleCityUpdate(city)
                  }}

                  handleCategoryUpdate={(category: Nullable<Category>) => {
                    setFieldTouched('name')
                    handleCategoryUpdate(category)
                  }}

                />
                <View style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  padding: 20,
                  flexWrap: 'wrap',
                }}>
                  <Button type="clear" onPress={handleSubmit} title="Update"
                    titleStyle={{color: theme.colors.primary}}
                    disabled={isSubmitting || Object.values(touched).every((v) => !v)}/>
                  <Button type="clear" onPress={() => {
                    updateImage(service)
                  }} title={service.attributes.imageUrl ? 'Update Image' : 'Add Image'}
                  titleStyle={{color: theme.colors.grey0}}/>
                  {service.attributes.imageUrl &&
                        <Button type="clear" onPress={() => {
                          removeImage(service)
                        }} title="Remove Image" titleStyle={{color: theme.colors.grey0}}/>
                  }
                  <Button type="clear" onPress={handleDelete} title="Delete"
                    titleStyle={{color: theme.colors.grey0}}/>
                </View>
              </View>
            )}
          </Formik>

        )
          :
          <Card containerStyle={{maxWidth: phoneWidth}}>
            <ShowServiceCard service={service} imageSize={400} navigation={navigation}/>
          </Card>
        )
        }
      </ScrollView>
    </HomeStackWrapper>
  );
}