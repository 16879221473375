/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import {LinkingOptions} from '@react-navigation/native';
import * as Linking from 'expo-linking';

import {RootStackParamList} from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Landing: 'landing',
      About: 'about',
      Login: 'login',
      Register: 'register',
      ResetPassword: 'reset-password',
      SendConfirmation: 'send-confirmation',
      ForgotPassword: 'forgot-password',
      Confirmation: 'confirmation',
      Dashboard: 'dashboard',
      Services: 'services',
      ServicesNew: 'services/new',
      ServicesShow: 'services/:id/:slug?',
      Profile: 'profile',
      Settings: 'settings',
      Support: 'support',
      Schedule: 'schedule',
      Search: 'search',
      Modal: 'modal',
      NotFound: '*',
    },
  },
};

export default linking;
