import {Avatar, Button, Text, useTheme} from '@rneui/themed';
import {Service} from '../../features/service/serviceSlice';
import {extendedApiSlice as extendedCityApiSlice} from '../../features/city/cityApi';
import {extendedApiSlice as extendedCategoryApiSlice} from '../../features/category/categoryApi';
import {extendedApiSlice as extendedUserApiSlice} from '../../features/user/userApi';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import {store} from '../../store/store';
import React, {useContext, useEffect, useState} from 'react';
import {City} from '../../features/city/citySlice';
import {cityLocationString as fetchCityLocationString, DEFAULT_LOCATION_STRING} from '../CityAutocomplete';
import {Nullable} from '../..';
import {Category} from '../../features/category/categorySlice';
import TagButton from '../TagButton';
import {User} from '../../features/user/userSlice';
import {AuthContext, AuthData} from '../../contexts/AuthProvider';
import ShowContactInfo from '../profile/ShowContactInfo';

export type ShowServiceCardStyles = { container: StyleProp<ViewStyle> }

const ShowServiceCard = ({
  service,
  styles = {container: {}},
  imageSize,
  navigation,
}: { service: Service, styles?: ShowServiceCardStyles, imageSize: number, navigation: any }) => {
  const [cityLocationString, setCityLocationString] = useState<string>(DEFAULT_LOCATION_STRING);
  const [category, setCategory] = useState<Nullable<Category>>(null);
  const [owner, setOwner] = useState<Nullable<User>>(null);

  const {theme} = useTheme()
  const {state} = useContext(AuthContext)
  const {currentUser} = state as AuthData

  useEffect(() => {

    const cityId = service.relationships.city.data?.id, categoryId = service.relationships.category.data?.id,
      ownerId = service.relationships.owner.data?.id;
    let city: Nullable<City> = null;

    if (cityId && cityLocationString == DEFAULT_LOCATION_STRING) {
      store.dispatch(extendedCityApiSlice.endpoints.getCityById.initiate(cityId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          city = entities[ids[0]] as City
          setCityLocationString(fetchCityLocationString(city) as string)
        }
      }).catch(console.error)
    }

    if (categoryId && category?.id !== categoryId) {
      store.dispatch(extendedCategoryApiSlice.endpoints.getCategoriesById.initiate(categoryId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          setCategory(entities[ids[0]] as Category)
        }
      }).catch(console.error)
    }

    if (ownerId && owner?.id !== ownerId && currentUser) {
      store.dispatch(extendedUserApiSlice.endpoints.getUserById.initiate(ownerId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          setOwner(entities[ids[0]] as User)
        }
      }).catch(console.error)
    }

  }, [service.relationships.city.data?.id, service.relationships.category.data?.id, service.relationships.owner.data?.id])

  return (
    <View style={styles.container}>
      <View style={[componentStyles.groupingView, {paddingBottom: 20}]}>
        <Text h4 style={{marginVertical: 5}}>{service.attributes.name}</Text>
        <Text style={{textDecorationLine: 'underline'}}>{cityLocationString}</Text>
      </View>

      {service.attributes.imageUrl &&
        <View>
          <Avatar
            size={imageSize}
            avatarStyle={{borderRadius: 5}}
            containerStyle={{width: '100%'}}
            source={{uri: service.attributes.imageUrl}}
          />
        </View>
      }

      <View style={{paddingTop: 20}}>

        {service.attributes.tags &&
          <View>
            <Text h4>Tags</Text>
            <View style={[componentStyles.pillLayout]}>
              {service.attributes.tags.map((tag, i) => (
                <TagButton key={i} tag={tag}/>
              ))}

            </View>
          </View>
        }

        {category &&

          <View style={componentStyles.groupingView}>
            <Text h4>Category</Text>
            <Text>{category.attributes.name!}</Text>
          </View>
        }

        <View style={componentStyles.groupingView}>
          <Text h4>Description</Text>
          <Text>{service.attributes.description}</Text>
        </View>

        <View style={componentStyles.groupingView}>
          <Text h4>Contact</Text>
          {
            (currentUser && owner) ?
              (
                <ShowContactInfo contactInfo={owner.attributes.contactInfo} owner={owner} service={service}
                  navigation={navigation}/>
              )
              :
              <View style={componentStyles.pillLayout}>
                <Button
                  title="Login for contact info"
                  type="clear"
                  onPress={() => {
                    navigation.push('Login')
                  }}
                  size="sm"
                  titleStyle={{color: theme.colors.black, textDecorationLine: 'underline'}}
                  containerStyle={{padding: 0, margin: 0}}
                />
              </View>
          }
        </View>
        <View style={componentStyles.groupingView}>
          <Text style={{textDecorationLine: 'underline'}}>{service.attributes.cost}</Text>
        </View>
      </View>
    </View>
  )
}

const componentStyles = StyleSheet.create({
  title: {marginVertical: 5},
  groupingView: {justifyContent: 'flex-start', paddingVertical: 10},
  pillLayout: {justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap'},
});

export default ShowServiceCard

export {
  ShowServiceCard,
}