import {createDrawerNavigator} from '@react-navigation/drawer';
import DashboardScreen from '../screens/homeScreens/DashboardScreen';
import ProfileScreen from '../screens/homeScreens/ProfileScreen';
import ServicesScreen from '../screens/homeScreens/ServicesScreen';
import SearchScreen from '../screens/homeScreens/SearchScreen';
import {RootStackParamList} from '../types';
import ShowScreen from '../screens/homeScreens/servicesScreens/ShowScreen';
import NewScreen from '../screens/homeScreens/servicesScreens/NewScreen';
import DrawerContent from '../screens/DrawerContent';
import {windowDimensions} from '../hooks/windowHooks';
import SettingsScreen from '../screens/homeScreens/SettingsScreen';
import SupportScreen from '../screens/homeScreens/SupportScreen';

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const HomeStack = createDrawerNavigator<RootStackParamList>();

export default function HomeStackNavigator() {
  const {isTabletOrSmallerScreen} = windowDimensions();

  return (
    <HomeStack.Navigator
      drawerContent={props => DrawerContent(props)}
      initialRouteName="Search"
      screenOptions={{
        drawerType: !isTabletOrSmallerScreen ? 'permanent' : 'front',
        headerShown: isTabletOrSmallerScreen,
      }}>
      <HomeStack.Screen name="Search" component={SearchScreen}/>
      <HomeStack.Screen name="Settings" component={SettingsScreen}/>
      <HomeStack.Screen name="Support" component={SupportScreen}/>
      <HomeStack.Screen name="Dashboard" component={DashboardScreen}/>
      <HomeStack.Screen name="Profile" component={ProfileScreen}/>
      <HomeStack.Screen name="Services" component={ServicesScreen} options={{title: 'Services'}}/>
      <HomeStack.Screen name="ServicesShow" component={ShowScreen} options={{title: 'Service'}}/>
      <HomeStack.Screen name="ServicesNew" component={NewScreen} options={{title: 'New Service'}}/>
    </HomeStack.Navigator>
  );
}