import { fetchUsers } from '../utils/fetchUtil';

const authHeader = (token: string) => {
  return {'Authorization': `Bearer ${token}`}
}

export const sendConfirmation = async (email: string): Promise<void> => {
  return await fetchUsers({path: '/confirmation', params: {user: {email}}})
}

export const confirm = async (token: string): Promise<void> => {
  return await fetchUsers({path: '/confirmation', params: {confirmation_token: token}, method: 'GET'})
}

export const sendPasswordResetInstructions = async (email: string): Promise<void> => {
  return await fetchUsers({path: '/password', params: {user: {email}}})
}

export const resetPassword = async (password: string, resetToken: string): Promise<void> => {
  return await fetchUsers({path: '/password', params: {user: {password, password_confirmation: password, reset_password_token: resetToken}}, method:  'PUT'})
}

export const register = async (email: string, password: string): Promise<void> => {
  return await fetchUsers({path: '/', params: {user: {email, password, password_confirmation: password}}})
}

export const signIn = async (email: string, password: string): Promise<string> => {
  return await fetchUsers({path: '/sign-in', params: {user: {email, password}}})
};
  
export const signOut = async (token: string): Promise<void> => {
  return await fetchUsers({path: '/sign-out', method: 'DELETE', headers: authHeader(token)})
}

export default {
  signIn, signOut, sendConfirmation, register, resetPassword, confirm, sendPasswordResetInstructions,
};