import {DrawerContentComponentProps, DrawerContentScrollView, DrawerItem} from '@react-navigation/drawer'
import {Avatar, useTheme} from '@rneui/themed'
import React, {useContext, useEffect, useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {AuthContext} from '../contexts/AuthProvider';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import {User} from '../features/user/userSlice';
import UserAvatar from 'react-native-user-avatar';
import {SupportContext} from '../contexts/SupportProvider';


export default function DrawerContent(props: DrawerContentComponentProps) {
  const navState = props.navigation.getState()
  const {theme} = useTheme()
  const {signOut, state} = useContext(AuthContext)
  const currentUser: null | User = state.currentUser;
  const {state: supportContext} = useContext(SupportContext)
  const {unreadCount} = supportContext
  const [supportLabel, setSupportLabel] = useState<string>('Support')

  useEffect(() => {
    let label = 'Support'
    if (unreadCount > 1) {
      label += ' (new messages)'
    } else if (unreadCount == 1) {
      label += ' (new message)'
    }
    setSupportLabel(label)
  }, [unreadCount])

  return (
    <View style={{flex: 1, backgroundColor: theme.colors.background}}>
      <DrawerContentScrollView {...props}>
        {currentUser &&
          <View>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  margin: 20,
                }}
              >
                {currentUser.attributes.avatarUrl ?
                  <Avatar
                    size={50}
                    rounded
                    source={{uri: currentUser.attributes.avatarUrl}}
                  />
                  :
                  <UserAvatar size={50} rounded name={currentUser.attributes.name || currentUser.attributes.email}/>
                }

                <View style={{marginLeft: 15, marginTop: 10, flexDirection: 'column'}}>
                  <Text style={{fontWeight: 'bold', color: theme.colors.grey0}}>
                    {currentUser.attributes.name}
                  </Text>
                  <Text style={{color: theme.colors.grey2, marginTop: 5}}>
                    {currentUser.attributes.email}
                  </Text>
                </View>

              </View>
            </View>
          </View>
        }
        <DrawerItem
          label="Search"
          activeTintColor={theme.colors.primary}
          focused={navState.index === navState.routeNames.indexOf('Search')}
          onPress={() => {
            props.navigation.navigate('Search')
          }}
          icon={({color, size}) => (
            <Icon
              name="magnify"
              color={color}
              size={size}/>
          )}
        />
        <DrawerItem
          label="Services"
          activeTintColor={theme.colors.primary}
          focused={navState.index === navState.routeNames.indexOf('Services')}
          onPress={() => {
            props.navigation.navigate('Services')
          }}
          icon={({color, size}) => (
            <Icon
              name="tractor"
              color={color}
              size={size}/>
          )}
        />
        <DrawerItem
          label="New"
          activeTintColor={theme.colors.primary}
          focused={navState.index === navState.routeNames.indexOf('ServicesNew')}
          onPress={() => {
            props.navigation.navigate('ServicesNew')
          }}
          icon={({color, size}) => (
            <Icon
              name="plus"
              color={color}
              size={size}/>
          )}
        />


      </DrawerContentScrollView>

      <DrawerItem
        label="Profile"
        activeTintColor={theme.colors.primary}
        focused={navState.index === navState.routeNames.indexOf('Profile')}
        onPress={() => {
          props.navigation.navigate('Profile')
        }}
        icon={({color, size}) => (
          <Icon
            name="account-outline"
            color={color}
            size={size}/>
        )}
      />

      <DrawerItem
        label="Settings"
        onPress={() => {
          props.navigation.navigate('Settings')
        }}
        activeTintColor={theme.colors.primary}
        focused={navState.index === navState.routeNames.indexOf('Settings')}
        icon={({color, size}) => (
          <Icon
            name="cog-outline"
            color={color}
            size={size}/>
        )}
      />

      <DrawerItem
        label={supportLabel}
        onPress={() => {
          props.navigation.navigate('Support')
        }}
        activeTintColor={theme.colors.primary}
        focused={navState.index === navState.routeNames.indexOf('Support')}
        icon={({color, size}) => (
          <Icon
            name="face-agent"
            color={color}
            size={size}/>
        )}
      />

      <DrawerItem
        label="Sign Out"
        onPress={() => {
          signOut()
        }}
        icon={({color, size}) => (
          <Icon
            name="exit-to-app"
            color={color}
            size={size}/>
        )}
      />

    </View>
  )
}

const styles = StyleSheet.create({
  subNavLabel: {paddingLeft: 90, paddingVertical: 0, marginVertical: 0},
  subNavStyle: {marginTop: -10, paddingTop: -10},
})