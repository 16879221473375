import React, {useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import {Button, Input, useTheme} from '@rneui/themed';
import {RootTabScreenProps} from '../../types';
import AuthStackWrapper from '../../components/authStack/AuthStackWrapper';
import {resetPassword} from '../../services/CurrentUserService';
import {sanitizedErrorsToFormik, sanitizeErrors} from '../../utils/ErrorUtil';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {AlertContext} from '../../contexts/AlertProvider';
import {compact} from 'lodash';
import ReactGA from 'react-ga4';
import {AUTHENTICATION_CATEGORY, RESET_PASSWORD_ACTION} from '../../utils/analyticsUtil';

import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {useTogglePasswordVisibility} from '../../hooks/formfieldHooks';

type SanitizedErrors = import('../..').SanitizedErrors;
type ApiErrorResponse = import('../..').ApiErrorResponse;

const styles = StyleSheet.create({
  containerStyle: {
    borderWidth: 1,
    borderRadius: 5,
  },
  actionButton: {
    marginTop: 10,
    minWidth: 200,
  },
  clearButton: {
    justifyContent: 'flex-start',
  },
  errors: {
    color: 'red',
  },
});


export default function ResetPasswordScreen({navigation, route}: RootTabScreenProps<'ResetPassword'>) {
  const {toast} = React.useContext(AlertContext);
  const defaultValues = {password: ''}
  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });
  const resetPasswordAttempts = useRef(0)

  const {passwordVisibility, rightIcon, handlePasswordVisibility} = useTogglePasswordVisibility();
  const {theme} = useTheme()

  return (
    <AuthStackWrapper onPress={() => {
      navigation.push('Landing')
    }}>
      <Formik
        enableReinitialize={true}
        validationSchema={passwordSchema}
        initialValues={defaultValues}
        onSubmit={async (values, {setErrors}) => {

          resetPasswordAttempts.current += 1;

          ReactGA.event({
            category: AUTHENTICATION_CATEGORY,
            action: RESET_PASSWORD_ACTION,
            value: resetPasswordAttempts.current,
          });

          try {
            await resetPassword(values.password, route.params['reset-token'])
            toast({message: 'Success. Password has been reset.', timeout: 6000})
            navigation.push('Login')
          } catch (err) {
            const sanitizedError = sanitizeErrors(err as Error | ApiErrorResponse) as SanitizedErrors

            if (sanitizedError) {
              const formikErrors = sanitizedErrorsToFormik(sanitizedError)
              setErrors(formikErrors)

              if (formikErrors.base || formikErrors.resetPasswordToken) {
                toast({message: compact([formikErrors.base, formikErrors.resetPasswordToken]).join(','), timeout: 6000})
              }
            } else {
              console.error(err)
            }
          }
        }}
      >
        {({errors, touched, handleChange, handleSubmit, values}) => (
          <View>
            <Input
              label="New Password"
              rightIcon={<MaterialCommunityIcons name={rightIcon} size={20} onPress={handlePasswordVisibility}
                color={theme.colors.grey2}/>}
              rightIconContainerStyle={{marginVertical: 0}}
              onChangeText={handleChange('password')}
              secureTextEntry={passwordVisibility}
              value={values.password}
              errorStyle={styles.errors}
              errorMessage={touched.password && errors.password}
              inputContainerStyle={styles.containerStyle}
              onSubmitEditing={() => {
                if (values.password) {
                  handleSubmit()
                }
              }}
            />
            <View style={{
              flex: 1,
              alignSelf: 'flex-center',
              alignItems: 'center',
            }}>
              <Button
                title="Back to login"
                buttonStyle={styles.clearButton}
                type="clear"
                onPress={() => {
                  navigation.push('Login', {email: values.email})
                }}
              />
            </View>
            <Button
              title="Update Password"
              loading={false}
              style={styles.actionButton}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>
    </AuthStackWrapper>
  );
}