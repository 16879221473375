import { camelCase, mapKeys } from 'lodash';
import { FormikErrors, SanitizedErrors } from '..';

export const sanitizeErrors = (errors: Record<string, any> | Error | Array<Record<string, any>>) => {
  if (errors instanceof Error) {
    return {base: [errors.message]}
  } else if (Array.isArray(errors)) {
    return {base: [errors[0].title]}
  } else if (typeof errors === 'object') {
    return mapKeys(errors, function (v, k) { return camelCase(k); });
  } else {
    console.error('NotImplemented', errors);
    throw 'NotImplemented'
  }
}

export const sanitizedErrorsToFormik = (errors: SanitizedErrors): FormikErrors => {
  const rtn = {}

  for (const [key, value] of Object.entries(errors)) {
    rtn[key] = value.join(',')
  }

  return rtn
}

export default {
  sanitizeErrors, sanitizedErrorsToFormik,
}