import {Coordinate, Nullable} from '../..'
import {Service} from '../../features/service/serviceSlice'
import {View} from 'react-native';
import React from 'react';
import {City} from '../../features/city/citySlice';
import {Category} from '../../features/category/categorySlice';
import FiltersPanel, {FiltersPanelStyles} from './FiltersPanel';
import {ServiceListingView, ServiceListingViewStyles} from './ServiceListingView';

export type SearchListStyles = { filtersPanelStyles: FiltersPanelStyles, serviceListingViewStyle: ServiceListingViewStyles }

const SearchList = ({
  region,
  radius,
  updateFilters,
  services,
  city,
  onServiceClick,
  onMapViewClick,
  category,
  setCategory,
  styles = {filtersPanelStyles: {}, serviceListingViewStyle: {scrollView: {}}},
  filterButtonsOutline,
}: { region: Nullable<Coordinate>, filterButtonsOutline: boolean, styles: SearchListStyles, updateFilters: Function, radius: number, setRadius: Function, region: Coordinate, onRegionChange: Function, services: Service[], setCity: Function, city: Nullable<City>, onServiceClick: Function, mapView: boolean, onMapViewClick: Function, category: Nullable<Category>, setCategory: Function }) => {

  return (
    <View style={{flex: 1}}>
      <FiltersPanel region={region} buttonsOutline={filterButtonsOutline} styles={styles.filtersPanelStyles}
        updateFilters={updateFilters} radius={radius} city={city}
        category={category}
        setCategory={setCategory} mapView={false} onMapViewClick={onMapViewClick}/>
      <ServiceListingView styles={styles.serviceListingViewStyle} services={services} onServiceClick={onServiceClick}/>
    </View>
  )
}

export default SearchList