import {Button, ButtonProps, useTheme} from '@rneui/themed';
import React from 'react';

export default function CategoryButton({
  category, size = 'sm', active = false, style, onPress = () => {
  },
}: { style: ButtonProps['buttonStyle'], category: string, size?: ButtonProps['size'], active?: boolean, onPress?: Function }) {

  const {theme} = useTheme();

  return (
    <Button
      type={active ? 'solid' : 'clear'}
      size={size}
      title={category}
      titleStyle={{color: active ? theme.colors.white : theme.colors.grey2}}
      buttonStyle={[style, {backgroundColor: active ? theme.colors.grey2 : theme.colors.white}]}
      onPress={onPress}/>
  );
}
