import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {RootTabScreenProps} from '../../../types';
import {Coordinate, EntitiesAdapterResult, Nullable} from '../../..';
import {Service} from '../../../features/service/serviceSlice';
import {City} from '../../../features/city/citySlice';
import {geoLocationHooks} from '../../../hooks/geoLocationHooks';
import {store} from '../../../store/store';
import {extendedApiSlice as extendedLocationApiSlice} from '../../../features/location/locationApi';
import {extendedApiSlice as extendedCityApiSlice} from '../../../features/city/cityApi';

import {Location} from '../../../features/location/locationSlice';
import {horshamGeolocation} from '../../../constants/Domain';
import Search from '../../../components/Search';

export default function SearchScreen({navigation}: RootTabScreenProps<'Search'>) {
  // GeoLocation
  const {coordinate, setCoordinate} = geoLocationHooks()
  const [region, setRegion] = useState<Nullable<Coordinate>>(null);

  const setCityFromData = async (data?: EntitiesAdapterResult<City>) => {
    if (data) {
      const {ids, entities} = data;
      const city = entities[ids[0]] as City

      try {
        const {data: lData} = await store.dispatch(extendedLocationApiSlice.endpoints.getLocationById.initiate(city.relationships.location.data.id))

        if (lData) {
          const {ids: lIds, entities: lEntities} = lData as EntitiesAdapterResult<Location>
          const location = lEntities[lIds[0]]
          const coordinate = {latitude: location.attributes.lat, longitude: location.attributes.lon}
          void setCoordinate(coordinate)
        }
      } catch (e) {
        //passthrough
      } finally {
        if (!coordinate && !region) {
          setCoordinate(horshamGeolocation)
        }
      }
    }
  }

  useEffect(() => {
    if (region) {
      return
    }

    if (coordinate) {
      setRegion(coordinate)
    }

    store.dispatch(extendedCityApiSlice.endpoints.getCityByIp.initiate()).then(({data, isSuccess}) => {
      if (isSuccess) {
        setCityFromData(data)
      } else if (!coordinate && !region) {
        setCoordinate(horshamGeolocation)
      }
    })

  }, [coordinate])

  return (
    <View style={{flexDirection: 'column', flex: 1, backgroundColor: 'white'}}>
      <Search defaultMapView={false} region={region} setRegion={setRegion} onServiceClick={(service: Service) => {
        navigation.navigate('ServicesShow', {id: service.id, slug: service.attributes.slug})
      }}/>
    </View>
  );
}