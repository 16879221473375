import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ScrollView, StyleSheet,View } from 'react-native';
import HomeStackWrapper from '../../../components/homeStack/HomeStackWrapper';
import { RootTabScreenProps } from '../../../types';
import { Card, Input, useTheme } from '@rneui/themed';
import { Button, Text } from '@rneui/themed';
import { extendedApiSlice, useAddServiceMutation } from '../../../features/service/serviceApi';
import { extendedApiSlice as extendedCityApiSlice } from '../../../features/city/cityApi';
import { Formik } from 'formik';
import Entypo from 'react-native-vector-icons/Entypo';
import { store } from '../../../store/store';
import { AuthContext } from '../../../contexts/AuthProvider';
import { ApiErrorResponse, BelongsTo, EntitiesAdapterResult, Nullable, SanitizedErrors } from '../../..';
import { City } from '../../../features/city/citySlice';
import CityAutocomplete from '../../../components/CityAutocomplete';
import { blankService, Service, serviceSchema } from '../../../features/service/serviceSlice';
import EditServiceCard from '../../../components/services/EditServiceCard';
import { useFocusEffect } from '@react-navigation/native';
import { AlertContext } from '../../../contexts/AlertProvider';
import { sanitizedErrorsToFormik, sanitizeErrors } from '../../../utils/ErrorUtil';
import { Category } from '../../../features/category/categorySlice';
import { windowDimensions } from '../../../hooks/windowHooks';
export default function NewScreen({ navigation }: RootTabScreenProps<'New'>) {
  const { theme } = useTheme()
  const { state: { currentUser} } = useContext(AuthContext)
  const {isPhoneOrSmallerScreen, phoneWidth, width} = windowDimensions();
  const [service, setService] = useState<Service>(blankService(currentUser))
  const { toast } = React.useContext(AlertContext);
  const containerWidth = isPhoneOrSmallerScreen ? width : phoneWidth;

  const handleCityUpdate = (city: Nullable<City>) => {
    if (!service) {
      return
    }
    const relationships = Object.assign({}, service.relationships, {city: {data: {id: city?.id || null, type: 'city' }}}) 
    setService(Object.assign({}, service, {relationships: relationships}))
  }

  const handleCategoryUpdate = (category: Nullable<Category>) => {
    if (!service) {
      return
    }
    const relationships = Object.assign({}, service.relationships, {category: {data: {id: category?.id || null, type: 'category' }}}) 
    setService(Object.assign({}, service, {relationships: relationships}))
  }

  useFocusEffect(useCallback(()=>{
    setService(blankService(currentUser))
  }, []))

  const handleErrors = (errors, setErrors) => {
    const sanitizedError = sanitizeErrors(errors as Error | ApiErrorResponse) as SanitizedErrors
              
    if (sanitizedError) {
      const formikErrors = sanitizedErrorsToFormik(sanitizedError)
      setErrors(formikErrors)

      if (formikErrors.base) {
        toast({message: formikErrors.base, timeout: 10000})
      }
    } else {
      console.error(errors)
    }           
  }

  return (
    <HomeStackWrapper navigation={navigation}> 
      <ScrollView horizontal={false} style={{padding: 10}}>
        { currentUser && 
        <Formik
          enableReinitialize={true}
          validationSchema={serviceSchema}
          initialValues={Object.assign({}, service.attributes)}
          onSubmit={async (values, {setErrors}) =>{

            const attributes = Object.assign({}, service.attributes, values)
            const newService = Object.assign({}, service, {attributes})
            
            try {
              const { error, data }  = await store.dispatch(extendedApiSlice.endpoints.addService.initiate(newService))

              if (data) {
                const { ids, entities } = data as EntitiesAdapterResult<Service>
                toast({message: 'Created Service'})
                navigation.navigate('ServicesShow', {id: ids[0], slug: entities[ids[0]].attributes.slug})
              } else if (error) {
                handleErrors(error.data.errors, setErrors)
              }
            } catch (error) {
              handleErrors(error, setErrors)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <View style={{maxWidth: containerWidth}}>
              <EditServiceCard setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} values={values} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} relationships={service?.relationships} handleCityUpdate={handleCityUpdate} handleCategoryUpdate={handleCategoryUpdate}/>
              <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', padding: 10}}>
                <Button type="clear" onPress={handleSubmit} title="Add Service" titleStyle={{color: theme.colors.primary}} disabled={isSubmitting || Object.values(touched).every((v)=> !v)} />
              </View>
            </View>
          )}
        </Formik>
        }
      </ScrollView>
    </HomeStackWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
