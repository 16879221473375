import React from 'react'
import {Animated, TouchableOpacity} from 'react-native'
import {Service} from '../../../features/service/serviceSlice';
import {Category} from '../../../features/category/categorySlice';

import {MaterialIcons} from '@expo/vector-icons';
import {Coordinate, Nullable} from '../../../index';
import {City} from '../../../features/city/citySlice';
import FiltersPanel, {FiltersPanelStyles} from '../FiltersPanel';
import SearchPanel from '../SearchPanel';

export type MobileSearchSidebarStyles = { filtersPanelStyles: FiltersPanelStyles }
const MobileSearchSidebar = ({
  width,
  services,
  serviceHover,
  activeService,
  onMapViewClick,
  updateFilters,
  radius,
  city,
  onServiceClick,
  category,
  setCategory,
  isHidden,
  toggleSlide,
  bounceValue,
  region,
  styles = {filtersPanelStyles: {}},
}: {
  region: Nullable<Coordinate>,
  width: number,
  services: Service[],
  serviceHover: Nullable<Service>,
  activeService: Nullable<Service>,
  onMapViewClick: Function,
  updateFilters: Function,
  radius: number,
  city: Nullable<City>,
  setCity: Function,
  onServiceClick: Function,
  category: Nullable<Category>,
  setCategory: Function,
  isHidden: boolean,
  toggleSlide: Function,
  bounceValue: Animated.Value,
  styles: MobileSearchSidebarStyles
}) => {

  return (
    <Animated.View style={{
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: 'white',
      width: width,
      transform: [{translateX: bounceValue}],
    }}>
      <TouchableOpacity onPress={() => {
        toggleSlide()
      }} style={{position: 'absolute', top: 250, left: width, backgroundColor: '#fff', paddingVertical: 20}}>
        <MaterialIcons name={isHidden ? 'arrow-right' : 'arrow-left'} size={24} color="black"/>
      </TouchableOpacity>
      <FiltersPanel region={region} mapView={true} onMapViewClick={onMapViewClick}
        styles={styles.filtersPanelStyles}
        updateFilters={updateFilters} radius={radius} city={city}
        category={category}
        setCategory={setCategory}/>

      <SearchPanel styles={{view: {padding: 0}}} serviceHover={serviceHover} onServiceClick={onServiceClick}
        width={width}
        activeService={activeService}
        services={services}/>
    </Animated.View>
  )
}

export default MobileSearchSidebar