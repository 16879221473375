import {Avatar, AvatarProps, Text} from '@rneui/themed';
import {Service} from '../../../features/service/serviceSlice';

import {StyleProp, TextStyle, TouchableOpacity, View, ViewStyle} from 'react-native';
import React, {useEffect, useState} from 'react';
import {Nullable} from '../../../index';
import {City} from '../../../features/city/citySlice';
import {cityLocationString as fetchCityLocationString, DEFAULT_LOCATION_STRING} from '../../CityAutocomplete';
import {Category} from '../../../features/category/categorySlice';
import {extendedApiSlice as extendedCityApiSlice} from '../../../features/city/cityApi';
import {extendedApiSlice as extendedCategoryApiSlice} from '../../../features/category/categoryApi';
import {store} from '../../../store/store';
import TagButton from '../../TagButton';

export type ServiceListingStyles = { view: StyleProp<ViewStyle>, infoBox: StyleProp<ViewStyle>, avatar: StyleProp<AvatarProps>, title: StyleProp<TextStyle>, cost: StyleProp<TextStyle>, location: StyleProp<TextStyle>, }

const ServiceListing = ({
  service,
  styles,
  avatarSize,
  infoBoxSize,
  onPress,
  isTitle = true,
  showTags = false,
}: { showTags: boolean, service: Service, styles: ServiceListingStyles, avatarSize: number, infoBoxSize: number, onPress: Function, isTitle: boolean }) => {

  const [city, setCity] = useState<Nullable<City>>(null);
  const [cityLocationString, setCityLocationString] = useState<string>(DEFAULT_LOCATION_STRING);
  const [category, setCategory] = useState<Nullable<Category>>(null);

  useEffect(() => {
    const cityId = service.relationships.city.data?.id, categoryId = service.relationships.category.data?.id;
    let city: Nullable<City> = null;

    if (cityId) {
      store.dispatch(extendedCityApiSlice.endpoints.getCityById.initiate(cityId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          city = entities[ids[0]] as City
          setCity(city)
          setCityLocationString(fetchCityLocationString(city) as string)
        }
      }).catch(console.error)
    }

    if (categoryId) {
      store.dispatch(extendedCategoryApiSlice.endpoints.getCategoriesById.initiate(categoryId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          setCategory(entities[ids[0]] as Category)
        }
      }).catch(console.error)
    }

  }, [service.relationships.city.data?.id, service.relationships.category.data?.id])


  return (
    <TouchableOpacity style={styles.view} onPress={onPress}>
      <Avatar size={avatarSize} avatarStyle={styles.avatar} title={service.attributes.name?.slice(0.2)}
        source={{uri: service.attributes.imageUrl}}/>
      <View style={[styles.infoBox, {width: infoBoxSize}]}>
        {showTags && service.attributes.tags?.length ?
          <View style={{flexDirection: 'row', flexWrap: 'wrap', paddingBottom: 10}}>
            {service.attributes.tags.map((tag, i) => (
              <TagButton key={i} tag={tag}/>
            ))}
          </View>
          : <></>
        }
        {isTitle ?
          <Text numberOfLines={1} style={styles.location}>{cityLocationString}</Text>
          :
          <></>
        }
        <Text numberOfLines={1} style={styles.title}>{service.attributes.name}</Text>
        <Text numberOfLines={1} style={styles.cost}>{service.attributes.cost}</Text>
      </View>
    </TouchableOpacity>
  );
}

export default ServiceListing;