import {Coordinate, EntitiesAdapterResult, Nullable} from '../..'
import {Service} from '../../features/service/serviceSlice'
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import {Animated, StyleSheet, View, ViewStyle} from 'react-native';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {distance, googleBoundsCoordinateToGoogleCoordinate, offsetGoogleMapWindow} from '../../utils/coordinateUtil';
import {debounce} from 'lodash';
import Marker from './marker/Marker';
import getEnvVars from '../../environment';
import {City} from '../../features/city/citySlice';
import {Category} from '../../features/category/categorySlice';
import {windowDimensions} from '../../hooks/windowHooks';
import FiltersPanel, {FiltersPanelStyles} from './FiltersPanel';
import MobileSearchSidebar, {MobileSearchSidebarStyles} from './searchPanel/MobileSearchSidebar';
import {SearchListStyles} from './SearchList';
import SearchPanel, {SearchPanelStyles} from './SearchPanel';
import {useTheme} from '@rneui/themed';
import {store} from '../../store/store';
import {extendedApiSlice as extendedLocationApiSlice} from '../../features/location/locationApi';
import {Location} from '../../features/location/locationSlice';
import {extendedApiSlice as extendedCityApiSlice} from '../../features/city/cityApi';

const {googleApiKey} = getEnvVars();

export type SearchMapStyles = { filtersPanelStyles: FiltersPanelStyles, searchPanelStyles: SearchPanelStyles, outerSearchPanelView: ViewStyle }

/* Web version only */
const SearchMap = ({
  region,
  onRegionChange,
  services,
  city,
  onServiceClick,
  category,
  setCategory,
  updateFilters,
  radius,
  onMapViewClick,
  mapRef,
  styles = {map: {filtersPanelStyles: {}, searchPanelStyles: {}}, list: {filtersPanelStyles: {}}},
}: {
  mapRef: React.MutableRefObject<null>,
  mapView: boolean,
  onMapViewClick: Function,
  styles: { map: SearchMapStyles, list: SearchListStyles },
  radius: number,
  updateFilters: Function,
  region: Coordinate,
  onRegionChange: Function,
  services: Service[],
  onServiceMarkerMouseOver: Function,
  city: City,
  onServiceClick: Function,
  category: Nullable<Category>,
  setCategory: Function
}) => {
  const categoryRef = useRef<Nullable<Category>>(null);
  const {isTabletOrSmallerScreen, width} = windowDimensions();

  const searchPanelWidth = 400
  const sidbarWidth = Math.min(Math.floor(width * 0.75), 500)
  const [bounceValue, setBounceValue] = useState(new Animated.Value(-sidbarWidth));
  const [isHidden, setIsHidden] = useState(true);
  const {theme} = useTheme()

  const [activeService, setActiveService] = useState<Nullable<Service>>(null);

  useEffect(() => {
    categoryRef.current = category
  }, [category])

  useEffect(() => {
    if (mapRef.current && activeService?.id) {
      const cityId = activeService.relationships.city.data.id
      store.dispatch(extendedCityApiSlice.endpoints.getCityById.initiate(cityId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          city = entities[ids[0]] as City
          store.dispatch(extendedLocationApiSlice.endpoints.getLocationById.initiate(city.relationships.location.data.id)).then(({data}) => {
            if (data) {
              const {ids: lIds, entities: lEntities} = data as EntitiesAdapterResult<Location>
              const location = lEntities[lIds[0]]

              const percentage = isTabletOrSmallerScreen ? 0.35 : 0.2
              const bounds = mapRef.current.getBounds()
              const southWest = googleBoundsCoordinateToGoogleCoordinate(bounds.getSouthWest()),
                northEast = googleBoundsCoordinateToGoogleCoordinate(bounds.getNorthEast());
              const offsets = offsetGoogleMapWindow({southWest, northEast, percentage})

              mapRef.current.setCenter({
                lng: location.attributes.lon - Math.abs(offsets.lng),
                lat: location.attributes.lat + Math.abs(offsets.lat),
              })
            }
          })
        }
      })
    }

  }, [activeService?.id])


  const toggleSlide = () => {
    let toValue = -sidbarWidth; //How to get dynamic height of View to animate

    if (isHidden) {
      //Here I hide (slide down) the animated View container
      toValue = 0;
    }

    Animated.spring(
      bounceValue,
      {
        toValue: toValue,
        velocity: 3,
        tension: 2,
        friction: 8,
        useNativeDriver: true,
      },
    ).start();

    setIsHidden(!isHidden);
  }

  const handleOnServiceClick = (service, marker = false) => {
    if (service.id == activeService?.id) {
      onServiceClick(service)
    } else {
      if (isTabletOrSmallerScreen && isHidden) {
        toggleSlide()
      }
      setActiveService(service)
    }
  }

  const handleLoad = (map: any) => {
    mapRef.current = map;
  }

  const changeHandler = () => {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    console.log(newPos, region)
    const newRegion = {latitude: newPos.lat, longitude: newPos.lng}

    /* Change is greater than 5km */
    if (distance(newRegion, region) > 5000) {
      setServiceHover(null)

      onRegionChange({coordinate: newRegion, category: categoryRef.current, city: city, radius: radius})
    }
  }

  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 1000)
    , []);

  const [serviceHover, setServiceHover] = useState<Nullable<Service>>(null);

  return (
    <View style={{flexGrow: 1}}>
      <View style={mapStyles.container}>
        <LoadScript
          googleMapsApiKey={googleApiKey}
        >
          <GoogleMap
            options={{streetViewControl: false, mapTypeControl: false, fullscreenControl: false}}
            onLoad={handleLoad}
            mapContainerStyle={mapStyles.container}
            center={{lat: region.latitude, lng: region.longitude}}
            onDragEnd={debouncedChangeHandler}
            zoom={10}
          >
            {services.map((service, index) => <Marker service={service} defaultPosition={region} key={index}
              onClick={(service) => {
                handleOnServiceClick(service, true)
              }}
              onMouseOver={setServiceHover}/>)}
          </GoogleMap>

        </LoadScript>
        {isTabletOrSmallerScreen ? (
          <>
            {isHidden && (
              <View style={{position: 'absolute', top: 0, backgroundColor: 'none', width: '100%'}} pointerEvents="none">
                <FiltersPanel region={region} mapView={true} onMapViewClick={onMapViewClick}
                  styles={styles.map.filtersPanelStyles}
                  updateFilters={(...args) => {
                    setActiveService(null)
                    updateFilters(...args)
                  }} radius={radius} city={city}
                  category={category}
                  setCategory={(category) => {
                    setActiveService(null)
                    setCategory(category)
                  }}/>
              </View>
            )}
            <MobileSearchSidebar services={services}
              serviceHover={serviceHover}
              onMapViewClick={onMapViewClick}
              updateFilters={(...args) => {
                setActiveService(null)
                updateFilters(...args)
              }}
              radius={radius}
              city={city}
              category={category}
              setCategory={(category) => {
                setActiveService(null)
                setCategory(category)
              }}
              region={region}
              onServiceClick={handleOnServiceClick}
              activeService={activeService}
              isHidden={isHidden}
              toggleSlide={toggleSlide}
              bounceValue={bounceValue}
              width={sidbarWidth}
              styles={styles.list as MobileSearchSidebarStyles}
            />
          </>
        ) : (
          <View style={{
            position: 'absolute',
            top: 0,
            backgroundColor: 'none',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
          }}
          pointerEvents="none">
            <FiltersPanel mapView={true} onMapViewClick={onMapViewClick} styles={styles.map.filtersPanelStyles}
              region={region}
              updateFilters={(...args) => {
                setActiveService(null)
                updateFilters(...args)
              }} radius={radius} city={city}
              category={category}
              setCategory={(category) => {
                setActiveService(null)
                setCategory(category)
              }}
              filterButtonsOutline={false}
            />
            {services.length ?
              <View style={Object.assign({}, styles.map.outerSearchPanelView, {
                width: searchPanelWidth,
                backgroundColor: theme.colors.white,
                flex: 1,
              })}
              pointerEvents="none">
                <SearchPanel styles={styles.map.searchPanelStyles}
                  serviceHover={serviceHover}
                  onServiceClick={handleOnServiceClick}
                  activeService={activeService}
                  width={searchPanelWidth} services={services}/>
              </View>
              : <></>
            }
          </View>
        )}
      </View>
    </View>
  )
}

export default SearchMap


const mapStyles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
});