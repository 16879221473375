import {Button, ButtonProps, makeStyles} from '@rneui/themed';
import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import { CategoryButtonStyles } from './CategoryButton';

export const GITHUB_TAG_BUTTON_BACKGROUND = '#ddf4ff';
export const GITHUB_TAG_BUTTON_COLOR = '#0969da';

export type TagButtonStyles = { style: StyleProp<ViewStyle> }

export default function TagButton({
  tag, size = 'sm', styles = {
    style: undefined
  }, onPress = () => {
  },
}: { tag: string, size?: ButtonProps["size"], styles?: CategoryButtonStyles, onPress?: Function }) {

  const bs = buttonStyles()

  return (
    <Button type="solid"
      size={size}
      color={GITHUB_TAG_BUTTON_BACKGROUND}
      style={[{paddingRight: 5, paddingBottom: 5}, styles.style]}
      titleStyle={{color: GITHUB_TAG_BUTTON_COLOR}}
      buttonStyle={[bs.buttonStyle]}
      onPress={onPress}>
      {tag}
    </Button>
  );
}


const buttonStyles = makeStyles((theme) => {
  return {
    buttonStyle: {borderRadius: 20, paddingVertical: 2, paddingHorizontal: 10},
  }
});