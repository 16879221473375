import {StackActions, useNavigation} from '@react-navigation/native';
import {Button, useTheme} from '@rneui/themed';
import React from 'react';
import {View} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faWheat} from '@fortawesome/pro-solid-svg-icons/faWheat';
import {windowDimensions} from '../../hooks/windowHooks';
import {companyNameStylized} from '../../constants/Domain';


export function HeaderTitle() {
  const {theme} = useTheme()
  const navigation = useNavigation();
  const {isPhoneOrSmallerScreen} = windowDimensions();
  const fontSize = isPhoneOrSmallerScreen ? 20 : 32, buttonSize = isPhoneOrSmallerScreen ? 'sm' : 'md';

  return (
    <View style={{flexDirection: 'row'}}>
      <Button
        type="clear"
        size={buttonSize}
        icon={<FontAwesomeIcon icon={faWheat} size={fontSize} color={theme.colors.primary}/>}
        title={companyNameStylized}
        titleStyle={{fontSize: fontSize, paddingLeft: 10, fontWeight: '400'}}
        onPress={() => {
          navigation.dispatch(StackActions.push('Landing'))
        }}
      />
    </View>
  );
}

export function ButtonsHeader() {
  const navigation = useNavigation();
  const {isPhoneOrSmallerScreen} = windowDimensions();
  const buttonSize = isPhoneOrSmallerScreen ? 'sm' : 'md';
  return (
    <View style={{flexDirection: 'row', paddingRight: 20}}>
      <Button
        title="Login"
        type="clear"
        size={buttonSize}
        onPress={() => {
          navigation.dispatch(StackActions.push('Login'))
        }}
      />
      <Button
        title="Register"
        size={buttonSize}
        onPress={() => {
          navigation.dispatch(StackActions.push('Register'))
        }}
      />
    </View>
  );
}

