import {apiSlice} from '../../services/apiSlice'
import normalize from 'json-api-normalizer';
import {createEntityAdapter} from '@reduxjs/toolkit';
import {EntitiesAdapterResult, Nullable} from '../..';
import {User} from '../user/userSlice';
import getEnvVars from '../../environment';
import {setQueryParams} from '../../utils/urlUtil';
import {isNil, omitBy} from 'lodash';

type Service = import('./serviceSlice').Service;
const {apiBaseUrl} = getEnvVars();

const normalizeApiResponse = (response: any) => {
  const data = Object.assign({}, normalize(response, {})).service

  return [undefined, null].includes(data) ? [] : Object.keys(data).map((key) => {
    return data[key]
  }) as Array<Service>
}

const servicesAdapter = createEntityAdapter<Service>({})
const initialState = servicesAdapter.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getServicesMarketing: builder.query<EntitiesAdapterResult<Service>, void>({
      query: () => ({url: 'services/marketing'}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response) as Array<Service>
        return servicesAdapter.setAll(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({type: 'Service', id} as const)),
      ],
    }),
    getServicesByOwner: builder.query<EntitiesAdapterResult<Service>, User>({
      query: (owner: User) => ({url: `services?owner_id=${owner.id}&owner_type=user`}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response) as Array<Service>
        return servicesAdapter.setAll(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({type: 'Service', id} as const)),
      ],
    }),
    getServicesByCoordinate: builder.query<EntitiesAdapterResult<Service>, Object>({
      query: ({
        latitude,
        longitude,
        categoryId,
        radius = 500000,
      }: { latitude: number, longitude: number, categoryId: Nullable<string>, radius: number }) => {

        const params = omitBy({
          radius,
          latitude,
          longitude,
          category_id: categoryId,
        }, isNil)

        let uri = new URL(apiBaseUrl)
        uri.pathname = 'services/search'
        uri = setQueryParams(uri, params)
        
        return {
          url: uri.pathname + uri.search,
          params: params,
        }
      },
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response) as Array<Service>
        return servicesAdapter.setAll(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({type: 'Service', id} as const)),
      ],
    }),
    getServiceById: builder.query<EntitiesAdapterResult<Service>, string>({
      query: (id: string) => ({url: `services/${id}`}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as Service
        return servicesAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({type: 'Service', id} as const)),
      ],
    }),
    addService: builder.mutation<EntitiesAdapterResult<Service>, Partial<Service>>({
      query(service: Partial<Service>) {
        return {
          url: 'services',
          method: 'POST',
          body: {data: service},
        }
      },
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as Service
        return servicesAdapter.setOne(initialState, normalized)
      },
    }),
    updateService: builder.mutation<EntitiesAdapterResult<Service>, Partial<Service>>({
      query: ({id, ...rest}) => {
        return {
          url: `/services/${id}`,
          method: 'PUT',
          body: {data: rest},
        }
      },
      invalidatesTags: (result, error, arg) => error && [] || [
        {type: 'Service', id: arg.id},
      ],
    }),
    updateImage: builder.mutation({
      query: ({service, formData}: { service: Service, formData: FormData }) => ({
        url: `services/${service.id}/update-image`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => error && [] || [
        {type: 'Service', id: arg.service.id},
      ],
    }),
    deleteService: builder.mutation<{ success: boolean; service: Service }, Service>({
      query({id}) {
        return {
          url: `services/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => error && [] || [{type: 'Service', id: arg.id}],
    }),
  }),
})

export const {
  useGetServicesMarketingQuery,
  useGetServicesByCoordinateQuery,
  useGetServicesByOwnerQuery,
  useGetServiceByIdQuery,
  useAddServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useUpdateImageMutation,
  useLazyGetServicesByCoordinateQuery,
  useLazyGetServiceByIdQuery,
  useLazyGetServicesByOwnerQuery,
} = extendedApiSlice