import { useWindowDimensions } from 'react-native';

export const windowDimensions = () => {
  const dimensions = useWindowDimensions();
  const width = dimensions.width;
  const height =  dimensions.height;
  const phoneWidth = 768, tabletWidth = 992;
  const isTabletOrSmallerScreen = width < tabletWidth;
  const isPhoneOrSmallerScreen = width < phoneWidth;

  return {
    isTabletOrSmallerScreen,
    isPhoneOrSmallerScreen,
    phoneWidth,
    tabletWidth,
    width,
    height,
  };
}