import React from 'react';
import { View, ViewStyle } from 'react-native';
import {useTheme } from '@rneui/themed'

export type HomeStackWrapperStyles =  {container: ViewStyle}

const HomeStackWrapper = ({children, styles={}}: {children: any, styles?: HomeStackWrapperStyles}) => {
  const { theme } = useTheme()
  
  return (
    <View style={[{
      flex: 1,
      backgroundColor: theme.colors.background,
      padding: 0,
      margin: 0,
    }, styles.container]}>
      {children}
    </View>
  )
}

export default HomeStackWrapper