import React, {createContext, useEffect, useMemo} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Coordinate, Nullable} from '..';

const serializeCoordinate = (coordinate: Coordinate): string => {
  return JSON.stringify(coordinate)
}

const deserializeCoordinate = (str: string | null | undefined): Nullable<Coordinate> => {
  if (str && str.length) {
    return JSON.parse(str)
  } else {
    return null
  }
}
export const GEO_LOCATION_KEY = 'geo-location-context-key';

export type GeoLocationData = {
  coordinate: null | Coordinate;
}

export type GeoLocationContextData = {
  state: GeoLocationData;
  set(coordinate: Coordinate): void;
  setFromBrowser(navigator: Navigator): void;
};

export const GeoLocationContext = createContext<GeoLocationContextData>({} as GeoLocationContextData);

export const GeoLocationProvider: React.FC = ({children}) => {
  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case 'SET':
          return {
            ...prevState,
            coordinate: action.coordinate,
          }
        case 'RESTORE_COORDINATE':
          return {
            ...prevState,
            coordinate: action.coordinate,
          }
      }
    },
    {
      coordinate: null,
    },
  );

  const geoLocationContext = useMemo(
    () => ({
      state,
      set: (coordinate: Coordinate) => {
        void AsyncStorage.setItem(GEO_LOCATION_KEY, serializeCoordinate(coordinate));
        return dispatch({type: 'SET', coordinate})
      },
      setFromBrowser: (navigator: Navigator) => {
        if (!navigator.geolocation) {
          throw 'Browser Not Supported';
        }

        navigator.geolocation.getCurrentPosition((position) => {
          if (position.coords) {

            const coordinate = Object.assign({}, {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }) as Coordinate

            void AsyncStorage.setItem(GEO_LOCATION_KEY, serializeCoordinate(coordinate));

            dispatch({
              type: 'SET',
              coordinate,
            })
          }
        })
      },
    }),
    [state.coordinate],
  );

  useEffect(() => {
    const bootstrapAsync = async () => {
      let coordinate;
      try {
        coordinate = deserializeCoordinate(await AsyncStorage.getItem(GEO_LOCATION_KEY) as null | string)
        if (coordinate) {
          dispatch({type: 'RESTORE_COORDINATE', coordinate});
        }
      } catch (e) {
        await AsyncStorage.removeItem(GEO_LOCATION_KEY)
      }
    };
    bootstrapAsync();
  }, []);

  return (
    <GeoLocationContext.Provider value={geoLocationContext}>
      {children}
    </GeoLocationContext.Provider>
  );
};

export default {
  GeoLocationContext, GeoLocationProvider,
}