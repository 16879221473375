import {Modal, Pressable, View} from 'react-native';
import {Button, Card, makeStyles, Slider, Text, useTheme} from '@rneui/themed';
import {windowDimensions} from '../../hooks/windowHooks';
import {City} from '../../features/city/citySlice';
import {Formik} from 'formik';
import {Nullable} from '../../index';
import React from 'react';
import CityAutocomplete from '../CityAutocomplete';
import {Category} from '../../features/category/categorySlice';
import CategoryButton from '../CategoryButton';

export default function FiltersModal({
  updateFilters,
  category,
  categories,
  isVisible,
  onClose,
  radius,
  city,
}: { updateFilters: Function, category: Nullable<Category>, categories: Array<Category>, isVisible: boolean, onClose: Function, radius: number, city: Nullable<City> }) {
  const {isPhoneOrSmallerScreen} = windowDimensions()
  const styles = modalStyles({isPhoneOrSmallerScreen})
  const {theme} = useTheme()

  return (
    <Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={() => onClose()}>
      <Pressable style={styles.centeredView}
        onPress={(event) => {
          if (event.target == event.currentTarget) {
            onClose();
          }
        }}
      >
        <Card containerStyle={styles.cardStyle}>
          <Card.Title h4>
            Filters
          </Card.Title>

          <Card.Divider/>
          <Formik enableReinitialize={true}
            initialValues={{radius, city: city || null, category: category || null}}
            onSubmit={(values) => {
              updateFilters({
                radius: values.radius,
                city: values.city,
                category: values.category,
              })

              onClose()
            }}
          >
            {({
              handleSubmit,
              values,
              isSubmitting,
              touched,
              setFieldValue,
              setFieldTouched,
            }) => (
              <View>
                <CityAutocomplete id={values.city?.id} onUpdate={(city: City) => {
                  setFieldValue('city', city)
                  setFieldTouched('city', true)
                }} label="Location"/>
                <View style={{paddingVertical: 10}}>
                  <Text style={{
                    color: '#808080',
                    fontWeight: '500',
                    paddingBottom: 5,
                  }}>Distance {values.radius} kilometers</Text>
                  <Slider
                    minimumTrackTintColor={theme.colors.primary}
                    thumbStyle={{width: 30, height: 30}}
                    thumbTintColor={theme.colors.primary}
                    value={values.radius}
                    onValueChange={(value: number) => {
                      setFieldValue('radius', value)
                      setFieldTouched('radius', true)
                    }}
                    maximumValue={2000}
                    minimumValue={10}
                    step={1}
                  />
                </View>
                <View style={{paddingBottom: 20}}>
                  <Text style={{
                    color: '#808080',
                    fontWeight: '500',
                    paddingBottom: 5,
                  }}>Category</Text>
                  {categories.length ?
                    <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                      {
                        categories.map((c: Category, i) => {
                          const isActive = values.category && values.category?.id == c.id
                          return <CategoryButton key={i} active={isActive} category={c.attributes.name}
                            onPress={() => {
                              const newCategory = isActive ? null : c
                              setFieldValue('category', newCategory)
                              setFieldTouched('category', true)
                            }}
                            styles={{style: {}}}/>
                        },
                        )
                      }
                    </View>
                    : <></>
                  }
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Button
                    type="clear"
                    onPress={() => onClose()}
                    disabled={isSubmitting}
                    color={theme.colors.grey2}
                    titleStyle={{color: theme.colors.grey2}}
                    title="close"/>
                  <Button
                    onPress={() => {
                      handleSubmit()
                    }}
                    disabled={isSubmitting || Object.values(touched).every((v) => !v)}
                    color={theme.colors.primary}
                    title="Update"/>
                </View>
              </View>
            )}
          </Formik>
        </Card>
      </Pressable>
    </Modal>
  );
}

const modalStyles = makeStyles((theme, {isPhoneOrSmallerScreen}: { isPhoneOrSmallerScreen: boolean }) => {
  return {
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(52, 52, 52, 0.8)',
      width: '100%',
      height: '100%',
    },
    cardStyle: {
      backgroundColor: 'white',
      minWidth: 400,
      maxWidth: 600,
    },
  }
})