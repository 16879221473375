import { StyleProp, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import { Text } from '@rneui/themed';
import * as Clipboard from 'expo-clipboard';

export type CopyTextStyles =  {touch: StyleProp<ViewStyle>, text: StyleProp<TextStyle> }

export default function CopyText({ text, copyText, styles }: { text: string, copyText?: string, styles?: CopyTextStyles }) {

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(copyText || text);
  };

  return (
    <TouchableOpacity onPress={copyToClipboard} style={styles?.touch || {}}>
      <Text style={styles?.text || {}}>{text}</Text>
    </TouchableOpacity>
   
  );
}