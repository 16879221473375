import React, {createContext, useEffect, useMemo} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const SUPPORT_KEY = 'support-context-key';

export type SupportData = {
  intercomVisible: boolean;
  unreadCount: number;
}

export type SupportContextData = {
  state: SupportData;
  set(intercomVisible: boolean): void;
  setCount(unreadCount: number): void;
};

export const SupportContext = createContext<SupportContextData>({} as SupportContextData);

export const SupportProvider: React.FC = ({children}) => {
  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case 'SET':
          return {
            ...prevState,
            intercomVisible: action.intercomVisible,
          }
        case 'SET_COUNT':
          return {
            ...prevState,
            unreadCount: action.unreadCount,
          }
        case 'RESTORE_STATE':
          return {
            ...prevState,
            intercomVisible: action.intercomVisible,
          }
      }
    },
    {
      intercomVisible: false,
      unreadCount: 0,
    },
  );

  const supportContext = useMemo(
    () => ({
      state,
      set: (intercomVisible: boolean) => {
        void AsyncStorage.setItem(SUPPORT_KEY, JSON.stringify(intercomVisible));
        return dispatch({type: 'SET', intercomVisible})
      },
      setCount: (unreadCount: number) => {
        return dispatch({type: 'SET_COUNT', unreadCount})
      },
    }),
    [state.intercomVisible],
  );

  useEffect(() => {
    const bootstrapAsync = async () => {
      let intercomVisible;
      try {
        intercomVisible = await AsyncStorage.getItem(SUPPORT_KEY) as null | string
        if (intercomVisible) {
          intercomVisible = JSON.parse(intercomVisible)
          dispatch({type: 'RESTORE_STATE', intercomVisible});
        }
      } catch (e) {
        await AsyncStorage.removeItem(SUPPORT_KEY)
      }
    };
    bootstrapAsync();
  }, []);

  return (
    <SupportContext.Provider value={supportContext}>
      {children}
    </SupportContext.Provider>
  );
};

export default {
  SupportContext, SupportProvider,
}