import {Avatar, Button, Card, Input, ListItem, Slider, Switch, Text, useTheme} from '@rneui/themed';
import {DEFAULT_RADIUS_KM_INITIAL, Service} from '../../features/service/serviceSlice';
import {extendedApiSlice as extendedCityApiSlice} from '../../features/city/cityApi';
import {extendedApiSlice as extendedCategoryApiSlice, useGetCategoriesQuery} from '../../features/category/categoryApi';
import {StyleSheet, View} from 'react-native';

import CityAutocomplete from '../CityAutocomplete';
import {Formik, FormikErrors, FormikTouched} from 'formik';
import React, {useEffect, useState} from 'react';
import {EntitiesAdapterResult, Nullable} from '../..';
import {store} from '../../store/store';
import {City} from '../../features/city/citySlice';
import {Category} from '../../features/category/categorySlice';
import {sanitizeSlug, serviceUrl} from '../../utils/stringUtil';
import CopyText from '../CopyText';
import TagButton from '../TagButton';
import CategoryButton from '../CategoryButton';

const EditServiceCard = ({
  relationships,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  handleCityUpdate,
  setFieldValue,
  setFieldTouched,
  handleCategoryUpdate,
  id,
}: { id?: Nullable<string>, relationships: Service['relationships'], errors: FormikErrors<Service['attributes']>, touched: FormikTouched<Service['attributes']>, values: Service['attributes'], handleChange: Function, handleBlur: Function, setFieldTouched: Function, setFieldValue: Function, handleCityUpdate: Function, handleCategoryUpdate: Function }) => {
  const [city, setCity] = useState<Nullable<City>>(null);
  const [category, setCategory] = useState<Nullable<Category>>(null);
  const {theme} = useTheme()
  const [expanded, setExpanded] = useState<boolean>(true)

  const {
    data: categoriesAdapterResult,
    error,
    isLoading,
    isSuccess,
    refetch,
  }: { data: EntitiesAdapterResult<Category>, error: any, isLoading: boolean, isSuccess: boolean } = useGetCategoriesQuery();

  useEffect(() => {
    setExpanded(!!values.radius)
  }, [])

  useEffect(() => {
    const cityId = relationships.city.data?.id, categoryId = relationships.category.data?.id;
    let city: Nullable<City> = null

    if (cityId) {
      store.dispatch(extendedCityApiSlice.endpoints.getCityById.initiate(cityId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          city = entities[ids[0]] as City
          setCity(city)
        }
      }).catch(console.error)
    }

    if (categoryId) {
      store.dispatch(extendedCategoryApiSlice.endpoints.getCategoriesById.initiate(categoryId)).then(({data}) => {
        if (data) {
          const {ids, entities} = data;
          setCategory(entities[ids[0]] as Category)
        }
      }).catch(console.error)
    } else {
      setCategory(null)
    }

  }, [relationships.city.data?.id, relationships.category.data?.id])

  return (
    <Card>
      {values.imageUrl &&
        <View style={{paddingBottom: 20}}>
          <Avatar
            size={200}
            avatarStyle={{borderRadius: 5}}
            containerStyle={{width: '100%'}}
            source={{uri: values.imageUrl}}
          />
        </View>
      }
      <View style={{flex: 1, flexDirection: 'colum'}}>

        <View>
          <View>
            <Input
              label="Name, i.e. Seeding, Spraying, etc..."
              onChangeText={handleChange('name')}
              onBlur={handleBlur('name')}
              value={values.name}
              errorStyle={styles.errors}
              errorMessage={errors.name && touched.name && errors.name}
            />

            <Input
              label="Description. It's recommend to add contact details here until communication features are released."
              onChangeText={handleChange('description')}
              onBlur={handleBlur('description')}
              value={values.description}
              multiline={true}
              numberOfLines={3}
              errorStyle={styles.errors}
              errorMessage={errors.description && touched.description && errors.description}
            />
            <CityAutocomplete
              id={relationships.city.data?.id || null}
              onUpdate={handleCityUpdate}
              label="Location"
            />


            <ListItem.Accordion
              noIcon={true}
              content={
                <View style={{flexDirection: 'row', paddingVertical: 10}}>
                  <Switch
                    value={!!values.radius}
                    onValueChange={(value: boolean) => {
                      setFieldValue('radius', value ? DEFAULT_RADIUS_KM_INITIAL : null)
                      setFieldTouched('radius', true)
                      setExpanded(value)
                    }}
                  />
                  <Text style={{color: '#808080', fontWeight: '500', paddingLeft: 10}}> Set travel distance.</Text>
                </View>
              }
              isExpanded={expanded}
            >
              <Text style={{
                color: '#808080',
                fontWeight: '500',
                paddingBottom: 5,
              }}>Distance {values.radius ? values.radius / 1000 : 0} kilometers</Text>
              <Slider
                minimumTrackTintColor={theme.colors.primary}
                thumbStyle={{width: 20, height: 20}}
                thumbTintColor={theme.colors.primary}
                value={values.radius}
                onValueChange={(value: number) => {
                  setFieldValue('radius', value)
                  setFieldTouched('radius', true)
                }}
                maximumValue={1000000}
                minimumValue={10000}
                step={5000}
              />
            </ListItem.Accordion>
            <Input
              label="Cost"
              onBlur={handleBlur('cost')}
              onChangeText={handleChange('cost')}
              value={values.cost}
              errorStyle={styles.errors}
              errorMessage={errors.cost && touched.cost && errors.cost}
            />

            <Formik
              enableReinitialize={true}
              initialValues={{tag: ''}}
              onSubmit={(tagValues) => {
                const tag = tagValues.tag.trim()
                if (tag.length) {
                  const tags = values.tags || [];
                  setFieldValue('tags', [...tags, tag])
                  setFieldTouched('tags', true)
                  tagValues.tag = ''
                }
              }}
            >
              {({
                handleChange: handleTagChange,
                handleBlur: handleTagBlur,
                handleSubmit: handleTagSubmit,
                values: tagValues,
              }) => (
                <View>

                  <Input
                    containerStyle={{flexGrow: 1}}
                    label="Add Tags for Search"
                    placeholder={values.tags.length >= 5 ? 'Limit Reached' : undefined}
                    onChangeText={handleTagChange('tag')}
                    onBlur={handleTagBlur('tag')}
                    value={tagValues.tag}
                    onSubmitEditing={handleTagSubmit}
                    disabled={values.tags.length >= 5}
                    rightIcon={<Button title="Add Tag"
                      type="outline"
                      size="sm"
                      color="#ffff"
                      onPress={handleTagSubmit}
                      style={{marginRight: 5}}
                      titleStyle={{color: theme.colors.grey0}}
                      buttonStyle={{borderWidth: 0, margin: 0}}/>}
                    rightIconContainerStyle={{marginVertical: 0}}
                  />
                  {values.tags &&
                    <View style={[styles.pillLayout]}>
                      {values.tags.map((tag, i) => (
                        <TagButton key={i}
                          tag={tag}
                          onPress={() => {
                            const newTags = values.tags.reduce((arr: Array<string>, curr: string) => {
                              if (curr !== tag) {
                                arr.push(curr)
                              }
                              return arr
                            }, [])

                            setFieldValue('tags', newTags)
                            setFieldTouched('tags', true)
                          }}
                        />
                      ))}
                    </View>
                  }
                </View>
              )}
            </Formik>

            {categoriesAdapterResult?.ids?.length > 0 &&
              <View style={[styles.groupingView, {marginTop: 20}]}>
                <Text style={{color: '#808080', fontWeight: '500', paddingBottom: 5}}>Category</Text>
                <View style={styles.tagsGroupingView}>
                  {categoriesAdapterResult.ids.map((id, i) => {
                    const currentSelected = id === category?.id
                    const c = categoriesAdapterResult.entities[id]
                    return (<CategoryButton key={i}
                      category={c.attributes.name!}
                      onPress={() => {
                        handleCategoryUpdate(currentSelected ? null : c)
                      }}
                      active={currentSelected}/>)
                  })}
                </View>
              </View>
            }

            <View>
              <Input
                label="Customize URL"
                onBlur={handleBlur('slug')}
                onChangeText={(slug: string) => {
                  setFieldValue('slug', sanitizeSlug(slug, 200))
                  setFieldTouched('slug', true)
                }}
                value={values.slug}
                errorStyle={styles.errors}
                errorMessage={errors.slug && touched.slug && errors.slug}
              />
              <CopyText styles={{text: {color: 'blue', fontWeight: '500', textDecorationLine: 'underline'}}}
                text={serviceUrl({id: id || 'id', slug: values.slug})}></CopyText>
            </View>

            <View style={{flexDirection: 'row', paddingTop: 20}}>
              <Switch
                value={values.privacy == 'is_public'}
                onValueChange={(value: boolean) => {
                  setFieldValue('privacy', value ? 'is_public' : 'is_private')
                  setFieldTouched('privacy', true)
                }}
              />
              <Text style={{color: '#808080', fontWeight: '500', paddingLeft: 10}}>Do you want your service to appear in
                Google Search?</Text>
            </View>
          </View>
        </View>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  tagsGroupingView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  title: {marginVertical: 5},
  groupingView: {justifyContent: 'flex-start', paddingVertical: 10},
  pillLayout: {justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap'},
  errors: {
    color: 'red',
  },
});

export default EditServiceCard
export {
  styles,
  EditServiceCard,
}