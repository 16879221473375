/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {NavigationContainer} from '@react-navigation/native';
import * as React from 'react';
import {useContext} from 'react';
import linking from './LinkingConfiguration';
import AppLoadingScreen from '../screens/AppLoadingScreen';
import {AuthContext} from '../contexts/AuthProvider';
import HomeStackNavigator from './HomeStack';
import AuthStackNavigator from './AuthStack';
import getEnvVars from '../environment';
import {SupportContext} from '../contexts/SupportProvider';
import {IntercomProvider} from 'react-use-intercom';

const {intercomId} = getEnvVars();
const Navigation = () => {
  const {state} = useContext(AuthContext)
  const {set, setCount} = useContext(SupportContext)
  return (
    <NavigationContainer linking={linking} fallback={<AppLoadingScreen/>}>
      {state.token == null ? (
        <AuthStackNavigator/>
      ) : (
        <IntercomProvider appId={intercomId}
          onShow={() => {
            set(true)
          }}
          onHide={() => {
            set(false)
          }}
          onUnreadCountChange={setCount}
        >
          <HomeStackNavigator/>
        </IntercomProvider>
      )}
    </NavigationContainer>
  );
}

export default Navigation