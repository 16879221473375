import React from 'react';
import {TouchableOpacity, useWindowDimensions, View} from 'react-native';
import {Text, useTheme} from '@rneui/themed';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {faWheat} from '@fortawesome/pro-solid-svg-icons/faWheat'
import {Nullable} from '../../index';
import {companyNameStylized} from '../../constants/Domain';
import {windowDimensions} from '../../hooks/windowHooks';

export type AuthStackWrapperStyles = { container: StyleProp<ViewStyle> }

const AuthStackWrapper = ({
  styles,
  children,
  onPress,
  hideTitle,
}: { hideTitle: Nullable<boolean>, styles?: AuthStackWrapperStyles, children: any, onPress?: Function }) => {
  const {theme} = useTheme()
  const {isPhoneOrSmallerScreen} = windowDimensions()
  const dimensions = useWindowDimensions();
  return (
    <View style={[{
      flex: isPhoneOrSmallerScreen ? null : 1,
      alignItems: 'center',
      backgroundColor: '#ffffff',
    }, (styles || {}).container || {}]}>
      <View style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 40,
      }}>
        {hideTitle ? <></> :
          <TouchableOpacity onPress={() => {
            onPress && onPress()
          }} style={{alignItems: 'center'}}>
            <FontAwesomeIcon icon={faWheat} color={theme.colors.primary} size={60} style={{paddingBottom: 10}}/>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                paddingBottom: (dimensions.height > 500 ? 20 : 0),
              }}>
              <Text h3 style={{color: theme.colors.primary}}>{companyNameStylized}</Text>
            </View>
          </TouchableOpacity>
        }
        {children}
      </View>
    </View>
  )
}

export default AuthStackWrapper
