
import { apiSlice } from '../../services/apiSlice'
import normalize from 'json-api-normalizer';
import { Category } from './categorySlice';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { EntitiesAdapterResult } from '../..';

const normalizeApiResponse = (response: any) => {
  const data = Object.assign({}, normalize(response, { })).category
  
  return [undefined, null].includes(data) ? [] : Object.keys(data).map((key) => { return data[key] }) as Array<Category>
}
  
const categoryAdapter = createEntityAdapter<Category>({})
const initialState = categoryAdapter.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCategoriesById: builder.query<EntitiesAdapterResult<Category>, string>({
      query: (id: string) => ({url: `categories/${id}`}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response)[0] as Category
        return categoryAdapter.setOne(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({ type: 'Category', id } as const)),
      ],
    }),
    getCategories: builder.query<EntitiesAdapterResult<Category>, void>({
      query: () => ({url: 'categories'}),
      transformResponse: (response) => {
        const normalized = normalizeApiResponse(response) as Array<Category>
        return categoryAdapter.setAll(initialState, normalized)
      },
      providesTags: (result, error, arg) => error && [] || [
        ...result.ids.map((id) => ({ type: 'Category', id } as const)),
      ],
    }),
  }),
})
  
export const { useGetCategoriesByIdQuery,  useGetCategoriesQuery, useLazyGetCategoriesByIdQuery, useLazyGetCategoriesQuery } = extendedApiSlice