
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AUTH_TOKEN_KEY } from '../contexts/AuthProvider';
import getEnvVars from '../environment';
import AsyncStorage from '@react-native-async-storage/async-storage';
import normalize from 'json-api-normalizer';

type User = import('../features/user/userApi').UserState;

const { apiBaseUrl, apiVersionPath } = getEnvVars();

const uri = new URL(apiBaseUrl)
uri.pathname = apiVersionPath

import { fetchApi } from '../utils/fetchUtil';

export const validateAuthToken = async (token: string): Promise<boolean> => {
  return (await fetchApi({path: '/users/validate-jwt-token', params: {token}}) as Record<'valid', boolean>).valid
}
  
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: uri.toString(),
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const token = await AsyncStorage.getItem(AUTH_TOKEN_KEY)
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      if (!['updateAvatar','updateImage'].includes(endpoint)) {
        headers.set('Content-Type', 'application/json')
      }
      
      return headers
    },
  }),
  tagTypes: ['User', 'Service', 'City', 'Location', 'Category'],
  endpoints: (builder) => ({}),
})
