import {Button, makeStyles, Text} from '@rneui/themed'
import React, {useContext, useEffect, useState} from 'react';
import {ContactInfo} from '../../index';
import {User} from '../../features/user/userSlice';
import Icon from 'react-native-vector-icons/Feather';
import {Linking, TextStyle, TouchableOpacity, View} from 'react-native';
import {mailToUrl, smsToUrl, telToUrl} from '../../utils/stringUtil';
import {Service} from '../../features/service/serviceSlice';
import {AuthContext, AuthData} from '../../contexts/AuthProvider';

const ShowContactInfo = ({
  contactInfo,
  owner,
  service,
  navigation,
}: { contactInfo: ContactInfo, owner: User, service: Service, navigation: any }) => {
  const contactStyles = styles()
  const [hasContactDetail, setHasContactDetail] = useState<boolean>(false);
  const {state} = useContext(AuthContext)
  const {currentUser} = state as AuthData

  useEffect(() => {
    if (!contactInfo.url && !contactInfo.mobile && !contactInfo.phone && !contactInfo.facebook && !contactInfo.email) {
      setHasContactDetail(false)
    } else {
      setHasContactDetail(true)
    }
  }, [contactInfo.url, contactInfo.mobile, contactInfo.phone, contactInfo.facebook, contactInfo.email])

  return (
    <View style={{backgroundColor: 'white'}}>
      {hasContactDetail ?
        <>
          {contactInfo.mobile &&
            <TouchableOpacity onPress={() => Linking.openURL(smsToUrl(contactInfo.mobile!))}>
              <Text style={contactStyles.urlStyle}>
                <Icon name="smartphone" style={contactStyles.iconStyle}/>
                {contactInfo.mobile}
              </Text>
            </TouchableOpacity>
          }

          {contactInfo.phone &&
            <TouchableOpacity onPress={() => Linking.openURL(telToUrl(contactInfo.phone!))}>
              <Text style={contactStyles.urlStyle}>
                <Icon name="phone" style={contactStyles.iconStyle}/>
                {contactInfo.phone}
              </Text>
            </TouchableOpacity>
          }

          {contactInfo.url &&
            <TouchableOpacity onPress={() => Linking.openURL(contactInfo.url!)}>
              <Text style={contactStyles.urlStyle}>
                <Icon name="link" style={contactStyles.iconStyle}/>
                {contactInfo.url}
              </Text>
            </TouchableOpacity>
          }

          {contactInfo.facebook &&
            <TouchableOpacity onPress={() => Linking.openURL(contactInfo.facebook!)}>
              <Text style={contactStyles.urlStyle}>
                <Icon name="facebook" style={contactStyles.iconStyle}/>
                {contactInfo.facebook}
              </Text>
            </TouchableOpacity>
          }

          {contactInfo.email &&
            <TouchableOpacity
              onPress={() => Linking.openURL(mailToUrl(owner.attributes.email!, service.attributes.name))}>
              <Text style={contactStyles.urlStyle}>
                <Icon name="mail" style={contactStyles.iconStyle}/>
                {owner.attributes.email}
              </Text>
            </TouchableOpacity>
          }
        </>
        : (
          currentUser?.id == owner.id ?
            <Button onPress={() => {
              navigation.navigate('Profile')
            }} containerStyle={{maxWidth: 200}}>Set Contact Details</Button>
            :
            <Text>No Contact Details Set</Text>
        )
      }
    </View>
  )
}

const styles = makeStyles((theme) => {
  return {
    urlStyle: {textDecorationLine: 'underline'} as TextStyle,
    iconStyle: {paddingRight: 5},
  }
});

export default ShowContactInfo